/* eslint-disable prettier/prettier */ /* eslint-disable react-hooks/exhaustive-deps */ import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { Theme, styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getBackgroundPostApi } from 'src/apis/commonSocialApi';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogAnswer from 'src/components/Dialog/DialogAnswer';
import DialogEventLife from 'src/components/Dialog/DialogEventLife/DialogEventLife';
import DialogGif from 'src/components/Dialog/DialogGif';
import DialogLocation from 'src/components/Dialog/DialogLocation';
import DialogMenuPost from 'src/components/Dialog/DialogMenuPost';
import DialogPostVisibility from 'src/components/Dialog/DialogPostVisibility';
import DialogSchedulePost from 'src/components/Dialog/DialogSchedulePost';
import DialogShowListBackground from 'src/components/Dialog/DialogShowListBackground';
import DialogTagFriend from 'src/components/Dialog/DialogTagFriend';
import { listMenuPost, listVisibility } from 'src/constants/common';
import { PAGE_TYPE } from 'src/constants/pageType';
import { PATHS } from 'src/constants/paths';
import { buttonColor, modalBackdrop, scrollStyle } from 'src/constants/styles';
import {
  resetErrorPost,
  updateIsLoad
} from 'src/store/action/socialPostAction';
import CUPostBottom from './CUPostBottom';
import CUPostCenter from './CUPostCenter';
import CUPostTop from './CUPostTop';
import { sortBy } from 'lodash';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    borderBottom: '0',
    ...scrollStyle
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  '& .MuiPaper-root': {
    width: 500
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  theme?: Theme;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, theme, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        borderBottom:
          theme?.palette.mode === 'light'
            ? '1px solid rgba(0, 0, 0, 0.12)'
            : '1px solid rgba(255, 255, 255, 0.336) !important'
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface CustomizedDialogs {
  type?: String | any;
  open: boolean;
  post?: any;
  handleClose?: any;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>> | any;
  textContent?: any;
  setTextContent?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenDialogAE?: React.Dispatch<React.SetStateAction<boolean>> | any;
  openUploadMedia?: boolean | any;
  setOpenUploadMedia?: React.Dispatch<React.SetStateAction<boolean>> | any;
  activitiesSelected?: any;
  parentSelected?: any;
  menuSelected?: any;
  setMenuSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  handleActionCreatePost?: any;
  pageInfo?: any;
  eventInfo?: any;
  projectInfo?: any;
  recruitInfo?: any;
  courseInfo?: any;
  group?: any; //info group shared
  page?: any; // info page shared
  groupShared?: any; // group receive this post
  pageShared?: any;
  event?: any;
  project?: any;
  recruit?: any;
  product?: any;
  course?: any;
  friendShared?: any;
  newFiles?: any;
  newLifeEvent?: any;
  share?: any;
  errorPost?: any;
  setObjectMentions?: React.Dispatch<React.SetStateAction<any>> | any;
  objectMentions?: any;
  setTextContentLive?: React.Dispatch<React.SetStateAction<any>> | any;
  idPostNoti?: String | any;
  setIdPostNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  updateTags?: any;
  setUpdateTags?: React.Dispatch<React.SetStateAction<any>> | any;
  shareTo?: any;
  groupSelected?: any;
  setSharePostNow?: React.Dispatch<React.SetStateAction<any>> | any;
  sharePostInNewFeed?: React.Dispatch<React.SetStateAction<any>> | any;
  setSharePostInNewFeed?: React.Dispatch<React.SetStateAction<any>> | any;
  music?: any;
  schedulePost?: any;
  setSchedulePost?: React.Dispatch<React.SetStateAction<any>> | any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
  isSharePost?: boolean;
}

const CustomizedDialogs = React.memo((props: any) => {
  const {
    type,
    open,
    post,
    handleClose,
    setOpen,
    textContent,
    setTextContent,
    setOpenDialogAE,
    openUploadMedia,
    setOpenUploadMedia,
    activitiesSelected,
    parentSelected,
    menuSelected,
    setMenuSelected,
    handleActionCreatePost,
    pageInfo,
    eventInfo,
    projectInfo,
    recruitInfo,
    courseInfo,
    group, //info group shared
    page, // info page shared
    groupShared, // group receive this post
    pageShared,
    event,
    project,
    recruit,
    product,
    course,
    friendShared,
    newFiles,
    newLifeEvent,
    share,
    errorPost,
    setObjectMentions,
    objectMentions,
    setTextContentLive,
    idPostNoti,
    setIdPostNoti,
    updateTags,
    setUpdateTags,
    shareTo,
    groupSelected,
    setSharePostNow,
    sharePostInNewFeed,
    setSharePostInNewFeed,
    music,
    schedulePost,
    setSchedulePost,
    setNotificationLeave,
    isSharePost
  } = props;

  const theme = useTheme();
  const isLoad = useSelector((state: any) => state.socialPostReducer.isLoad);
  const settingPage = useSelector(
    (state: any) => state.pageReducer?.settingPage
  );
  const dialogRef: any = React.useRef();
  const history = useHistory();
  const match = useRouteMatch();
  const [files, setFiles] = React.useState<any>([]);
  const [visibility, setVisibility] = React.useState(listVisibility[0]);
  const [backgroundSelected, setBackgroundSelected] = React.useState<any>(null);
  const [friendSelected, setFriendSelected] = React.useState<any>([]);
  const [locationSelected, setLocationSelected] = React.useState<any>(null);
  const [listKeyDisabled, setListKeyDisabled] = React.useState<any>([]);
  const [gifSelected, setGifSelected] = React.useState<any>(null);
  const [lifeEvent, setLifeEvent] = React.useState<any>(null);
  const [updateEvent, setUpdateEvent] = React.useState(false);
  const [bgAnswerSelected, setBgAnswerSelected] = React.useState<any>({
    backgroundImage: '',
    text: 'Bạn hỏi gì đi...',
    isChange: false,
    isTarget: false
  });

  const [isTypeTarget, setIsTypeTarget] = React.useState<any>(false);
  const [linkCard, setLinkCard] = React.useState<any>(null);
  const [listBackground, setListBackground] = React.useState<any>([]);
  const [isDisabledButton, setIsDisabledButton] = React.useState(false);
  const [openBackground, setOpenBackground] = React.useState(false);
  const [openVisibility, setOpenVisibility] = React.useState(false);
  const [openMenuPost, setOpenMenuPost] = React.useState(false);
  const [openTagFriend, setOpenTagFriend] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);
  const [openEventLife, setOpenEventLife] = React.useState(false);
  const [openGif, setOpenGif] = React.useState(false);
  const [openAnswer, setOpenAnswer] = React.useState(false);
  const [openPoll, setOpenPoll] = React.useState(false);
  const [pollData, setPollData] = React.useState<any>(null);
  const [openSchedule, setOpenSchedule] = React.useState(false);
  const [showBGDemo, setShowBGDemo] = React.useState(false);
  const [eventLife, setEventLife] = React.useState<any>(null);
  const handleCloseSchedule = type => {
    setOpenSchedule(false);
    if (type === 'submit') {
      setOpen(true);
    }
  };

  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const rolePreviewEdit: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );

  const handleVisibility = key => {
    let visibilitySelected: any = listVisibility?.find(
      (el: any) => el.key === key
    );
    setVisibility(visibilitySelected);
  };

  React.useEffect(() => {
    if (shareTo === 'friend') {
      setVisibility(listVisibility[1]);
    }
  }, [shareTo]);

  React.useEffect(() => {
    if (post) {
      let visibilitySelected: any = listVisibility?.find(
        (el: any) => el.key === post.visibility
      );
      setVisibility(visibilitySelected);
    }
  }, []);

  const fetchListBackground = async () => {
    let res = await getBackgroundPostApi();
    setListBackground(res.data);
  };

  const handleAction = key => {
    setMenuSelected(prev => (prev.includes(key) ? prev : [...prev, key]));
    switch (key) {
      case 'media':
        if (type === 'update_product' || type === 'product') {
          setOpenUploadMedia(false);
          setShowBGDemo(false);
        } else {
          setOpenUploadMedia(true);
          setShowBGDemo(false);
        }
        break;
      case 'emoji-activity':
        return setOpenDialogAE(true);
      case 'tag-people':
        return setOpenTagFriend(true);
      case 'checkin':
        return setOpenLocation(true);
      case 'life-event':
        return setOpenEventLife(true);
      case 'gif':
        return setOpenGif(true);
      case 'answer':
        setIsTypeTarget(false);
        return setOpenAnswer(true);
      case 'poll':
        return setOpenPoll(true);
      case 'target':
        setIsTypeTarget(true);
        return setOpenAnswer(true);
      case 'live':
        if (match.path === PATHS.PAGE_DETAIL) {
          history.push(`/live/?page=${pageInfo.id}`);
        } else {
          history.push('/live');
        }
        break;
      default:
        return;
    }
  };
  const handleDeleteKey = key => {
    let index = menuSelected?.indexOf(key);
    if (index >= 0) {
      let dataState = [...menuSelected];
      dataState.splice(index, 1);
      setMenuSelected(dataState);
    }
  };

  const handleTextContent = () => {
    const regex = /@\[([^\]]+)\]\((\d+)\)/g;
    let contentPost = textContent.replace(regex, '[$2]');
    return contentPost;
  };

  const handleCreatePost = () => {
    // kiểm tra list files upload, nếu có 1 file > 500MB thì báo lỗi
    const maxSize = 500 * 1024 * 1024;
    for (const file of files) {
      if (file.size > maxSize) {
        setNoti({
          code: 400,
          message:
            'Video tải lên vượt quá dung lượng cho phép. Vui lòng tải lên video có dung lượng dưới 500MB.'
        });
        setOpenSnackbar(true);
        return;
      }
    }
    let typePost;
    if (bgAnswerSelected.isChange && bgAnswerSelected.isTarget) {
      typePost = 'target';
    } else if (bgAnswerSelected.isChange && !bgAnswerSelected.isTarget) {
      typePost = 'question';
    } else if (
      (pageInfo?.id || post?.page?.id) &&
      files?.[0]?.type.includes('video')
    ) {
      typePost = 'watch';
    } else if (post?.scheduled_at) {
      typePost = 'scheduled_at';
    } else {
      typePost = null;
    }

    if (type === 'livestream') {
      setTextContentLive(handleTextContent());
      setTextContent('');
      setOpen(false);
    } else if (type === 'product' || type === 'update_product') {
      let data: any = {
        id: share ? null : post ? post.id : null,
        life_event: null,
        visibility: visibility.key,
        status: handleTextContent(),
        shared_product_id: product?.id,
        place_id: locationSelected?.id
          ? locationSelected?.id
          : locationSelected,
        media_ids: files,
        mention_ids: friendSelected.length
          ? friendSelected?.map((el: any) => el.id)
          : null,
        reblog_of_id: null,
        group_id: groupShared?.id,
        target_account_id: friendShared?.id,
        groupInfo: groupShared
          ? {
              groupRelationship: groupShared?.group_relationship,
              groupName: groupShared?.title,
              permission: groupShared?.post_permission_setting
            }
          : null
      };
      dispatch(resetErrorPost());
      handleActionCreatePost(data);
    } else {
      let data: any = sharePostInNewFeed
        ? {
            visibility: visibility.key,
            extra_body: {},
            life_event: null,
            poll: null,
            mention_ids: friendSelected.length
              ? friendSelected?.map((el: any) => el.id)
              : null,
            reblog_of_id:
              post && (type !== 'update' || type !== 'update_product')
                ? post.reblog
                  ? post.reblog.id
                  : post.id
                : null,
            post_type: null,
            place_id: locationSelected?.id
              ? locationSelected?.id
              : locationSelected,
            status: handleTextContent(),
            shared_group_id: group?.id,
            shared_page_id: page?.id,
            shared_event_id: event?.id,
            shared_project_id: project?.id,
            shared_music_id: music?.id,
            shared_recruit_id: recruit?.id,
            shared_course_id: course?.id,
            target_account_id: friendShared?.id,
            tags: objectMentions?.map((el: any) => ({
              entity_id: el.id,
              entity_type: el.type,
              position: el.position
            }))
          }
        : {
            id: share ? null : post ? post.id : null,
            media_ids: files,
            sensitive: false,
            visibility: post?.group ? 'public' : visibility?.key,
            extra_body: gifSelected
              ? {
                  title: '',
                  description: '',
                  link: gifSelected
                }
              : linkCard
              ? linkCard[0]
              : {},
            life_event: lifeEvent
              ? {
                  ...lifeEvent,
                  default_media_url: files.length
                    ? null
                    : lifeEvent.default_media_url
                }
              : null,
            poll: pollData,
            pinned: post?.pinned,
            place_id: locationSelected?.id
              ? locationSelected?.id
              : locationSelected,
            status_background_id:
              textContent && backgroundSelected?.id
                ? backgroundSelected?.id
                : null,
            mention_ids: friendSelected.length
              ? friendSelected?.map((el: any) => el.id)
              : null,
            reblog_of_id:
              post && type !== 'update'
                ? post.reblog
                  ? post.reblog.id
                  : post.id
                : null,
            post_type: typePost,
            group_id: groupShared?.id,
            page_id:
              (((type === 'page' || type === 'header-post-page') &&
                pageInfo?.id) ||
                post?.page?.id) &&
              (settingPage?.visitor_posts !== 'disable' ||
                (settingPage?.visitor_posts === 'disable' &&
                  rolePreviewEdit?.role))
                ? pageInfo?.id
                : pageShared?.id
                ? pageShared.id
                : null,

            page_owner_id:
              course && course?.page_owner // Create post in course created by page
                ? course?.page_owner?.id
                : pageInfo?.id && rolePreviewEdit.role
                ? pageInfo.id
                : null,
            event_id:
              (type === 'event' && eventInfo?.id) || post?.event?.id
                ? eventInfo?.id || post.event.id
                : null,
            project_id:
              (type === 'project' && projectInfo?.id) || post?.project?.id
                ? projectInfo?.id || post.project.id
                : null,
            recruit_id:
              (type === 'recruit' && recruitInfo?.id) || post?.recruit?.id
                ? recruitInfo?.id || post.recruit.id
                : null,
            course_id:
              (type === 'course' && courseInfo?.id) || post?.course?.id
                ? courseInfo?.id || post.course.id
                : null,
            status_question:
              share || typePost !== 'question'
                ? null
                : {
                    content: bgAnswerSelected.text,
                    color: bgAnswerSelected?.backgroundImage
                  },
            status_target:
              share || typePost !== 'target'
                ? null
                : {
                    content: bgAnswerSelected.text,
                    color: bgAnswerSelected?.backgroundImage
                  },
            tags: objectMentions?.map((el: any) => ({
              entity_id: el.id,
              entity_type: el.type,
              position: el.position
            })),
            status: handleTextContent(),
            shared_group_id: group?.id,
            shared_page_id: page?.id,
            shared_event_id: event?.id,
            shared_project_id: project?.id,
            shared_music_id: music?.id,
            shared_recruit_id: recruit?.id,
            shared_course_id: course?.id,
            target_account_id: friendShared?.id,
            scheduled_at: schedulePost ? schedulePost : null,
            post_permission_setting: groupSelected?.post_permission_setting
          };
      if (shareTo === 'friend') {
        data = {
          ...data,
          page_id: null,
          page_owner_id: null,
          group_id: null,
          visibility: visibility.key,
          shared_page_id:
            (((type === 'page' || type === 'header-post-page') &&
              pageInfo?.id) ||
              post?.page?.id) &&
            (settingPage?.visitor_posts !== 'disable' ||
              (settingPage?.visitor_posts === 'disable' &&
                rolePreviewEdit.role))
              ? pageInfo?.id || post.page.id
              : pageShared?.id
              ? pageShared.id
              : null,
          shared_group_id: groupShared?.id ?? groupSelected?.id
        };
      } else if (shareTo === 'page' || shareTo === 'group') {
        data = {
          ...data,
          event_id: null,
          page_id: pageShared?.id,
          group_id: groupShared?.id,
          groupInfo: groupShared
            ? {
                groupRelationship: groupShared?.group_relationship,
                groupName: groupShared?.title,
                permission: groupShared?.post_permission_setting
              }
            : null
        };
      }
      dispatch(resetErrorPost());
      handleActionCreatePost(data, null, setOpenUploadMedia);
      setSharePostNow && setSharePostNow(false);
      setSharePostInNewFeed && setSharePostInNewFeed(false);
      setLifeEvent(null);
    }

    if (idPostNoti) {
      setIdPostNoti(null);
    }
  };

  const handleAddKey = key => {
    setMenuSelected(prev => (prev.includes(key) ? prev : [...prev, key]));
  };

  React.useEffect(() => {
    if (newFiles) setFiles(newFiles);
    if (newFiles) setFiles(sortBy(newFiles, 'position'));
    if (newLifeEvent) setLifeEvent(newLifeEvent);
    if (post?.life_event && !lifeEvent && open && type === 'update') {
      setLifeEvent({
        ...post.life_event,
        place_object: post.life_event.place
          ? {
              ...post.life_event.place,
              title: post.life_event.place.title
            }
          : null,
        place_id: post.life_event.place ? post.life_event.place.id : null,
        life_event: post.life_event.life_event_category
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (!listBackground.length && open) {
      fetchListBackground();
    }
  }, [open]);

  React.useEffect(() => {
    if (!friendSelected.length) {
      handleDeleteKey('tag-people');
    }
    if (!locationSelected) {
      handleDeleteKey('checkin');
    }
    if (!activitiesSelected) {
      handleDeleteKey('emoji-activity');
    }
    if (!gifSelected) {
      handleDeleteKey('gif');
    }
    if (!bgAnswerSelected.isChange) {
      handleDeleteKey('answer');
    }
    if (!pollData && !openPoll) {
      handleDeleteKey('poll');
    }
    if (!lifeEvent) {
      handleDeleteKey('life-event');
    }
  }, [
    open,
    gifSelected,
    openPoll,
    JSON.stringify(pollData),
    JSON.stringify(bgAnswerSelected),
    JSON.stringify(friendSelected),
    JSON.stringify(locationSelected),
    JSON.stringify(activitiesSelected),
    JSON.stringify(lifeEvent)
  ]);

  React.useEffect(() => {
    if (!listMenuPost || !menuSelected) return;

    let listKey = Array.from(
      new Set(
        listMenuPost
          ?.filter((el: any) => menuSelected?.includes(el?.key))
          .flatMap((item: any) => {
            if (!item?.disabled || item?.disabled?.length === 0) {
              return [];
            } else {
              return item?.disabled;
            }
          })
      )
    );
    setListKeyDisabled(prev => {
      const updatedKeys = new Set([...prev, ...listKey]);
      return Array.from(updatedKeys);
    });
  }, [JSON.stringify(menuSelected), listMenuPost]);

  React.useEffect(() => {
    if (
      (!bgAnswerSelected?.id &&
        !group &&
        !pageShared &&
        !friendShared &&
        textContent?.trim().length === 0 &&
        friendSelected.length === 0 &&
        files.length === 0 &&
        !locationSelected &&
        !gifSelected &&
        !sharePostInNewFeed &&
        !lifeEvent &&
        !activitiesSelected &&
        !parentSelected &&
        !event?.id &&
        !course?.id &&
        !project?.id &&
        !music &&
        !recruit?.id &&
        type !== 'product' &&
        post?.post_type !== 'moment') ||
      (post && (post.poll?.expired || post.poll?.votes_count >= 1))
      // ||
      // (!textContent &&
      //   !activitiesSelected &&
      //   (type === 'update' || type === 'update_product'))
    ) {
      setIsDisabledButton(true);
    } else {
      setIsDisabledButton(false);
    }
  }, [
    textContent,
    visibility,
    JSON.stringify(post),
    JSON.stringify(files),
    JSON.stringify(friendSelected),
    JSON.stringify(locationSelected),
    JSON.stringify(gifSelected),
    JSON.stringify(activitiesSelected),
    JSON.stringify(bgAnswerSelected),
    JSON.stringify(lifeEvent),
    sharePostInNewFeed
  ]);

  React.useEffect(() => {
    if (isLoad === 'success' && errorPost?.code === undefined) {
      handleClose();
      setFiles([]);
      setBackgroundSelected(null);
      setFriendSelected([]);
      setLocationSelected(null);
      setListKeyDisabled([]);
      setGifSelected(null);
      setBgAnswerSelected(prev => ({
        ...prev,
        text: isTypeTarget
          ? 'Hãy viết mục tiêu của bạn...'
          : 'Hãy viết câu hỏi của bạn...',
        isChange: false,
        isTarget: false
      }));
      setLinkCard(null);
      setPollData(null);
      setOpenPoll(false);
      dispatch(updateIsLoad(null));
    }
  }, [isLoad]);

  React.useEffect(() => {
    if (errorPost?.code) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: errorPost.message
      });
    }
  }, [JSON.stringify(errorPost)]);

  React.useEffect(() => {
    if (post && (type === 'update' || type === 'update_product') && open) {
      if (post.status_background && !backgroundSelected) {
        handleAddKey('background');
        setBackgroundSelected(post.status_background);
      }
      if (post?.mentions?.length && !updateTags) {
        setFriendSelected(post.mentions);
        handleAddKey('tag-people');
      } else {
        setFriendSelected(prev => friendSelected);
        handleAddKey('tag-people');
      }
      if (post.place) {
        setLocationSelected(post.place);
        handleAddKey('checkin');
      }
      if (
        post?.card &&
        !post.card?.title?.length &&
        (post?.card?.url || post?.card?.link)
      ) {
        setGifSelected(
          post.card
            ? post.card?.title?.length
              ? null
              : post.card.url ?? post.card.link
            : null
        );
        handleAddKey('gif');
      }

      setLinkCard(post.card && post.card?.title?.length ? [post.card] : null);

      if (post.poll) {
        handleAddKey('poll');
        setOpenPoll(true);
      }

      if (post.media_attachments.length) {
        if (post.life_event) {
          setOpenUploadMedia(false);
        } else {
          setOpenUploadMedia(true);
        }
        handleAddKey('media');
        let newFiles = post.media_attachments?.map((el: any) => ({
          ...el,
          path: el.url,
          preview: el.type === 'video' ? el.url : el.preview_url
        }));
        setFiles(sortBy(newFiles, 'position'));
      }

      if (post?.post_type === 'target' || post?.post_type === 'question') {
        setBgAnswerSelected({
          backgroundImage:
            post?.status_target?.color || post?.status_question?.color,
          text: post?.status_target?.content || post?.status_question?.content,
          isChange: true,
          isTarget: true
        });
        post?.post_type === 'target'
          ? setIsTypeTarget(true)
          : post?.post_type === 'question' && setIsTypeTarget(false);
      }
    }
  }, [type, JSON.stringify(post), open]);

  React.useEffect(() => {
    if (openUploadMedia) {
      let indexBg = menuSelected?.findIndex((el: any) => el === 'background');
      if (indexBg >= 0 && menuSelected?.includes('media')) {
        setMenuSelected(prev => [
          ...prev.slice(0, indexBg),
          ...prev.slice(indexBg + 1)
        ]);
        setBackgroundSelected(null);
      }
    }
  }, [openUploadMedia]);

  // React.useEffect(() => {
  //   dispatch(scrollSocial('fixed'));

  //   return () => {
  //     dispatch(scrollSocial('static'));
  //   };
  // }, [open]);

  const renderTextSchedulePost = () => {
    const dateAndTime = schedulePost.split(' ');

    const dateComponents = dateAndTime[0].split('/');
    const day = dateComponents[0];
    const month = dateComponents[1];
    const year = dateComponents[2];

    const timeComponents = dateAndTime[1].split(':');
    const hour = timeComponents[0];
    const minute = timeComponents[1];
    return (
      <div
        style={{
          padding: '6px 16px 0px 16px',
          position: 'relative'
        }}
      >
        <Typography
          textAlign={'center'}
          sx={{
            fontSize: 15,
            color: '#00364d',
            fontWeight: 500,
            backgroundColor: '#f1f4f6',
            borderRadius: '6px',
            padding: '8px'
          }}
        >
          {`Bài viết sẽ được đăng vào ${hour}:${minute} ngày ${day}-${month}-${year}`}
        </Typography>
        <i
          className="fa-light fa-circle-x"
          onClick={() => {
            setSchedulePost(null);
          }}
          style={{
            position: 'absolute',
            top: 3,
            fontWeight: 500,
            right: 9,
            zIndex: 2,
            cursor: 'pointer',
            backgroundColor: '#fff',
            borderRadius: '50%'
          }}
        ></i>
      </div>
    );
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
        ref={dialogRef}
      >
        {isLoad === 'loading' ? (
          <div
            style={{
              height: '100%',
              width: 500,
              position: 'absolute',
              zIndex: 2000,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: 'rgba(255,255,255,0.6)'
            }}
          >
            <CircularProgress color="inherit" />
            <Typography style={{ fontSize: 20 }}>Đang đăng</Typography>
          </div>
        ) : null}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          theme={theme}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            {type === 'update' || type === 'update_product'
              ? 'Chỉnh sửa bài viết'
              : 'Tạo bài viết'}
          </Typography>
        </BootstrapDialogTitle>
        <CUPostTop
          post={post}
          type={type}
          eventVisibility={eventInfo?.visibility}
          locationSelected={locationSelected}
          friendSelected={friendSelected}
          visibility={visibility}
          setVisibility={setVisibility}
          activitiesSelected={activitiesSelected}
          parentSelected={parentSelected}
          setOpenVisibility={setOpenVisibility}
          setOpenTagFriend={setOpenTagFriend}
          setOpenLocation={setOpenLocation}
          groupShared={groupShared}
          pageShared={pageShared}
          friendShared={friendShared}
          pageInfo={pageInfo}
          eventInfo={eventInfo}
          projectInfo={projectInfo}
          course={courseInfo}
          sharePostInNewFeed={sharePostInNewFeed}
        />
        <DialogContent style={{ position: 'relative' }}>
          <CUPostCenter
            type={type}
            post={post}
            files={files}
            linkCard={linkCard}
            setLinkCard={setLinkCard}
            setFiles={setFiles}
            openPoll={openPoll}
            setOpenPoll={setOpenPoll}
            pollData={pollData}
            setPollData={setPollData}
            handleAction={handleAction}
            setNotificationLeave={setNotificationLeave}
            bgAnswerSelected={bgAnswerSelected}
            setBgAnswerSelected={setBgAnswerSelected}
            gifSelected={gifSelected}
            setGifSelected={setGifSelected}
            handleDeleteKey={handleDeleteKey}
            locationSelected={locationSelected}
            openUploadMedia={openUploadMedia}
            setOpenUploadMedia={setOpenUploadMedia}
            textContent={textContent}
            setTextContent={setTextContent}
            handleClose={handleClose}
            listBackground={listBackground}
            backgroundSelected={backgroundSelected}
            setOpenBackground={setOpenBackground}
            setBackgroundSelected={setBackgroundSelected}
            lifeEvent={lifeEvent}
            setLifeEvent={setLifeEvent}
            setEventLife={setEventLife}
            setUpdateEvent={setUpdateEvent}
            setMenuSelected={setMenuSelected}
            setOpenEventLife={setOpenEventLife}
            group={group}
            page={page}
            event={event}
            eventInfo={eventInfo}
            project={project}
            projectInfo={projectInfo}
            music={music}
            recruit={recruit}
            course={course}
            courseInfo={courseInfo}
            product={product}
            setObjectMentions={setObjectMentions}
            objectMentions={objectMentions}
            isTypeTarget={isTypeTarget}
            setIsTypeTarget={setIsTypeTarget}
            showBGDemo={showBGDemo}
            setShowBGDemo={setShowBGDemo}
            sharePostInNewFeed={sharePostInNewFeed}
            friendShared={friendShared}
            pageShared={pageShared}
            groupShared={groupShared}
            container={dialogRef.current}
            setListKeyDisabled={setListKeyDisabled}
          />
        </DialogContent>
        <CUPostBottom
          listKeyDisabled={listKeyDisabled}
          menuSelected={menuSelected}
          handleActionKey={handleAction}
          share={share}
          sharePostInNewFeed={sharePostInNewFeed}
          setListKeyDisabled={setListKeyDisabled}
          newLifeEvent={newLifeEvent}
          setOpenMenuPost={setOpenMenuPost}
          type={type}
          idRecruit={recruit?.id}
          idProject={project?.id}
          idCourse={course?.id}
          event={event}
          post={post}
        />
        {schedulePost && renderTextSchedulePost()}
        <DialogActions>
          <div
            style={{
              width: 500,
              padding: '0px 8px 8px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <ButtonInherit
              disabled={isDisabledButton}
              fullWidth={true}
              action={() => {
                handleCreatePost();
                setNotificationLeave(false);
              }}
              style={{
                color: '#e7f3ff',
                backgroundColor: buttonColor.backgroundColor,
                minWidth: '405px',
                opacity: isDisabledButton ? '0.5' : '1'
              }}
              label={
                type === 'update' ||
                type === 'livestream' ||
                type === 'update_product'
                  ? 'Lưu'
                  : 'Đăng'
              }
            />
            {((type === PAGE_TYPE.group &&
              (groupShared.group_relationship?.admin ||
                groupShared.group_relationship?.moderator)) ||
              (rolePreviewEdit.role && pageInfo)) && (
              <ButtonInherit
                label={<i className="far fa-calendar-alt fa-lg"></i>}
                action={() => {
                  setOpenSchedule(true);
                }}
                style={{
                  marginRight: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 0,
                  paddingRight: 0,
                  minWidth: 48,
                  backgroundColor: 'button.secondary.background'
                }}
              />
            )}
          </div>
        </DialogActions>
      </BootstrapDialog>
      {openSchedule && (
        <DialogSchedulePost
          minDay={new Date()}
          open={openSchedule}
          handleClose={handleCloseSchedule}
          setSchedulePost={setSchedulePost}
        />
      )}
      {openVisibility && (
        <DialogPostVisibility
          open={openVisibility}
          setOpen={setOpenVisibility}
          handleOther={() => setOpen(true)}
          value={visibility.key}
          handleAction={handleVisibility}
        />
      )}
      {openMenuPost && (
        <DialogMenuPost
          open={openMenuPost}
          setOpen={setOpenMenuPost}
          post={post}
          listKeyDisabled={listKeyDisabled}
          setListKeyDisabled={setListKeyDisabled}
          menuSelected={menuSelected}
          handleActionKey={handleAction}
          idRecruit={recruit?.id}
          idProject={projectInfo?.id}
          idCourse={courseInfo?.id}
          idGroup={groupShared?.id}
          idPage={pageInfo?.id}
          idEvent={eventInfo?.id}
          handleOther={() => setOpen(true)}
          isSharePost={isSharePost}
        />
      )}
      {openBackground && (
        <DialogShowListBackground
          open={openBackground}
          listBackground={listBackground}
          setBackgroundSelected={setBackgroundSelected}
          handleAction={handleAction}
          handleClose={() => {
            setOpen(true);
            setOpenBackground(false);
          }}
        />
      )}
      {openTagFriend && (
        <DialogTagFriend
          open={openTagFriend}
          handleClose={() => {
            setOpen(true);
            setOpenTagFriend(false);
            setUpdateTags && setUpdateTags(false);
          }}
          friendSelected={friendSelected}
          setFriendSelected={setFriendSelected}
          updateTags={updateTags}
          setUpdateTags={setUpdateTags}
        />
      )}
      {openLocation && (
        <DialogLocation
          open={openLocation}
          handleClose={() => {
            setOpen(true);
            setOpenLocation(false);
          }}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
          type="post"
        />
      )}
      {openEventLife && (
        <DialogEventLife
          open={openEventLife}
          handleClose={() => {
            setOpen(true);
            setOpenEventLife(false);
            setUpdateEvent(false);
            // setFiles([]);
          }}
          files={files}
          setFiles={setFiles}
          setLifeEvent={setLifeEvent}
          eventLife={eventLife}
          setEventLife={setEventLife}
          updateEvent={updateEvent}
        />
      )}
      {openGif && (
        <DialogGif
          open={openGif}
          setGifSelected={setGifSelected}
          handleClose={() => {
            setOpen(true);
            setOpenGif(false);
          }}
          setMenuSelected={setMenuSelected}
        />
      )}
      {openAnswer && (
        <DialogAnswer
          open={openAnswer}
          bgAnswerSelected={bgAnswerSelected}
          setBgAnswerSelected={setBgAnswerSelected}
          setListKeyDisabled={setListKeyDisabled}
          handleClose={() => {
            setOpen(true);
            setOpenAnswer(false);
          }}
          setMenuSelected={setMenuSelected}
          isTypeTarget={isTypeTarget}
          pageInfo={pageInfo}
        />
      )}
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
});

export default CustomizedDialogs;

import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { Grid, Hidden, Tooltip, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ResponseApi } from 'src/@types/api';
import { countListen, getTopMusicApi } from 'src/apis/SocialMusic.api';
import { postDonatePage } from 'src/apis/socialPayment.api';
import logoEmso from 'src/assets/images/LogoEmso.svg';
import DialogPayment from 'src/components/Dialog/DialogPayment';
import { discountData } from 'src/constants/common';
import {
  actionMusic,
  activeMusic,
  getListMusic,
  getVolumeMusic,
  isShowMiniAudio,
  randomMusic
} from 'src/store/action/socialMusicAction';
import MenuOption from '../../MusicComponent/MenuOption';
import { scrollSocialHeader } from 'src/store/action/socialScrollAction';

const useStyles = makeStyles((theme: any) => ({
  boxPlayer: {
    height: '90px',
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    bottom: 0,
    borderTop:
      theme.palette.mode === 'dark' ? '1px solid #121212' : '1px solid #F2F2F2',
    background: theme.palette.mode === 'dark' ? '#000000' : '#fff',
    position: 'fixed',
    right: 0,
    zIndex: 10,
    padding: '0px 75px 0px 20px'
  },
  actionVolume: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'end !important'
  },
  menu: {
    '& .MuiPaper-root': {
      top: 'unset !important',
      bottom: '100px !important'
    },
    '& .MuiList-root': {
      padding: '0px '
    }
  },
  menuDonate: {
    '& .MuiPaper-root': {
      display: 'none'
    }
  },
  boxSlider: {
    position: 'absolute',
    right: 10,
    bottom: 95,
    width: '350px',
    minWidth: '250px',
    height: '120px',
    backgroundColor: '#242526',
    borderRadius: '8px'
  },
  imageMedia: {
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '8px 0px 0px 8px',
    position: 'relative',
    zIndex: 1
  },
  boxAction: {
    padding: '0 10px',
    width: 'calc(100% - 120px)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'move',
    '&:hover': {
      '& .MuiBox-root': {
        opacity: 1
      }
    },
    overflow: 'hidden'
  },
  '@keyframes marquee': {
    from: {
      // Giá trị ban đầu
      transform: 'translate(0, 0)'
    },
    to: {
      // Giá trị cuối cùng
      transform: 'translate(-100%, 0)'
    }
  },
  marquee: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    top: 4
  },
  marqueeText: {
    display: 'inline-block',
    animation: '$marquee 10s linear infinite',
    paddingLeft: '100%'
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  coverImage: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  boxItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 50px)',
    alignItems: 'center'
  },
  itemFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
const Widget = styled('div')(({ theme }) => ({
  borderRadius: 16,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  zIndex: 1,
  backgroundColor: 'primary.main'
}));
const CoverImage = styled('div')({
  width: 65,
  height: 65,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: 'primary.main',
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
});
const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.6,
  fontWeight: 500,
  letterSpacing: 0.2
});
type Position = {
  xRate: number;
  yRate: number;
};

interface Props {
  data?: any;
  setOpenMiniPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  openMiniPlayer: boolean;
  setNoti: React.Dispatch<React.SetStateAction<any>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
}

const MusicPlayerSlider = (props: Props) => {
  const { data, setOpenMiniPlayer, openMiniPlayer, setNoti, setOpenSnackbar } =
    props;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const audioRef: any = React.useRef();
  const playAudio = useSelector((state: any) => state.musicReducer.playAudio);
  const audioIndex = useSelector((state: any) => state.musicReducer.audioIndex);
  const mute = useSelector((state: any) => state.musicReducer.mute);
  const volume = useSelector((state: any) => state.musicReducer.volumeMusic);
  const isReplay = useSelector((state: any) => state.musicReducer.isReplay);
  const isPatchTrack = history.location.pathname.includes('/music_space/track');
  const isPlaylist = history.location.pathname.includes(
    '/music_space/my_Playlist'
  );

  const showDataPlaylist = useSelector(
    (state: any) => state.musicReducer.dataDetailPlaylist
  );
  const paused = useSelector((state: any) => state.musicReducer.paused);
  const isShowAudio = useSelector(
    (state: any) => state.musicReducer.isShowMiniAudio
  );
  const dispatch = useDispatch();
  const [duration, setDuration] = React.useState<any>();
  const [currentTime, setCurrentTime] = React.useState(0);
  const [hasCall, setHasCall] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [idMusic, setIdMusic] = React.useState<any>(null);
  const [dataMusic, setDataMusic] = React.useState<null>(null);
  const [isRandomMusic, setIsRandomMusic] = React.useState<boolean>(false);
  const [isLoopMusic, setIsLoopMusic] = React.useState<boolean>(false);
  const [loadingDonate, setLoadingDonate] = React.useState<any>(false);

  const [currentPosition, setCurrentPosition] = React.useState<Position>({
    xRate: 10,
    yRate: 10
  });

  const [previousPath, setPreviousPath] = React.useState<any>(false);
  const responsiveRedux = useSelector((state: any) => state?.scrollReducer);
  const { marginRight, widthHeader } = responsiveRedux || {};

  React.useEffect(() => {
    if (history.location.pathname.includes('/music_space')) {
      setPreviousPath(true);
      setOpenMiniPlayer(true);
    }
    if (history.location.pathname === '/music_space') {
      dispatch(isShowMiniAudio(false));
    }
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (currentTime > 30 && !hasCall) {
      setHasCall(true);
      countListen({
        music_id: data[audioIndex]?.id,
        view_30s: true
      });
    }
  }, [currentTime]);

  const [openDialogDonate, setOpenDialogDonate] =
    React.useState<null | HTMLElement>(null);

  const openDonate = Boolean(openDialogDonate);
  const matches = useMediaQuery('(max-width:1000px)');
  const [exchangeSelected, setExchangeSelected] = React.useState<any>(
    discountData[0]
  );
  const [openDialogPaymentDetail, setOpenDialogPaymentDetail] =
    React.useState<any>(false);

  const handlePaymentDonate = async (data: any) => {
    try {
      let res;

      res = await postDonatePage(data);

      if (res.status === 200) {
        setLoadingDonate(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Ủng hộ thành công.'
        });
      }
    } catch (err: any) {
      setLoadingDonate(false);
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message:
          err.response.status === 422
            ? err.response.data.error
            : 'Donate thất bại!'
      });
    }
  };
  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }
  const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';

  function isLastObject(index, array) {
    const lastIndex = array.length - 1;
    return index === lastIndex;
  }

  const getTopMusic = async () => {
    try {
      let res: ResponseApi = await getTopMusicApi({ limit: 10 });
      if (res.status === 200) {
        const item = res.data.musics.filter(
          el => !showDataPlaylist?.some(item => item?.music?.id === el?.id)
        );
        dispatch(
          getListMusic({
            data: item,
            index: 0,
            playAudio: true
          })
        );
        dispatch(activeMusic(item[0]));
        dispatch(randomMusic(true));
      }
    } catch (error) {}
  };

  const handlePausePlayClick = () => {
    dispatch(
      actionMusic({
        audioIndex: audioIndex,
        playAudio: !paused,
        paused: !paused
      })
    );
    if (paused) {
      dispatch(activeMusic(data[audioIndex], isPatchTrack || isPlaylist));
      audioRef?.current?.pause();
    } else {
      dispatch(activeMusic(data[audioIndex], isPatchTrack || isPlaylist));
      audioRef?.current?.play();
    }
  };

  const handleLoadedData = () => {
    setDuration(audioRef.current.duration);
    setCurrentTime(audioRef.current.currentTime);
    if (paused) audioRef.current.play();
  };

  const handleTimeSliderChange = x => {
    setCurrentTime(x);
    audioRef.current.currentTime = x;
    if (!paused) {
      dispatch(
        actionMusic({
          audioIndex: audioIndex,
          playAudio: playAudio,
          paused: true
        })
      );
      audioRef.current.play();
    }
  };

  const handleNextSong = () => {
    const randomIndex = Math.floor(Math.random() * data.length);
    if (audioIndex === data?.length - 1) {
      dispatch(actionMusic({ audioIndex: 0, playAudio: true, paused: true }));
      dispatch(activeMusic(data[0]));
    } else {
      dispatch(
        actionMusic({
          audioIndex: isRandomMusic ? randomIndex : audioIndex + 1,
          playAudio: true,
          paused: true
        })
      );
      dispatch(activeMusic(data[isRandomMusic ? randomIndex : audioIndex + 1]));
    }
  };
  const handlePrevSong = () => {
    const randomIndex = Math.floor(Math.random() * data.length);

    if (audioIndex > 0) {
      dispatch(
        actionMusic({
          audioIndex: isRandomMusic ? randomIndex : audioIndex - 1,
          playAudio: playAudio,
          paused: paused
        })
      );
      dispatch(activeMusic(data[isRandomMusic ? randomIndex : audioIndex - 1]));
    }
  };

  const endedEvent = () => {
    const randomIndex = Math.floor(Math.random() * data.length);
    const lastItem = isLastObject(audioIndex, data);

    if (lastItem) {
      dispatch(
        getListMusic({
          data: [],
          index: 0,
          playAudio: false
        })
      );
      getTopMusic();
    }

    if (audioIndex < data?.length - 1) {
      dispatch(
        actionMusic({
          audioIndex: isRandomMusic ? randomIndex : audioIndex + 1,
          playAudio: playAudio,
          paused: paused
        })
      );
      dispatch(activeMusic(data[isRandomMusic ? randomIndex : audioIndex + 1]));
    } else if (audioIndex === data?.length - 1) {
      dispatch(
        actionMusic({
          audioIndex: isRandomMusic ? randomIndex : 0,
          playAudio: playAudio,
          paused: paused
        })
      );
      dispatch(activeMusic(data[isRandomMusic ? randomIndex : 0]));
    }
  };

  const countListenMusic = () => {
    countListen({
      music_id: data[audioIndex]?.id
    });
  };

  React.useEffect(() => {
    if (playAudio) {
      countListenMusic();
    }
  }, [data[audioIndex]?.id, playAudio]);

  // phát lại từ đầu
  React.useEffect(() => {
    if (isReplay) {
      audioRef.current.currentTime = 0;
      audioRef?.current?.play();
      dispatch(
        actionMusic({
          audioIndex: audioIndex,
          playAudio: playAudio,
          paused: true
        })
      );
    }
  }, [isReplay]);

  React.useEffect(() => {
    if (playAudio) {
      audioRef?.current?.play();
      dispatch(
        actionMusic({
          audioIndex: audioIndex,
          playAudio: playAudio,
          paused: true
        })
      );
    } else {
      audioRef?.current?.pause();
      dispatch(
        actionMusic({
          audioIndex: audioIndex,
          playAudio: playAudio,
          paused: false
        })
      );
    }
  }, [playAudio]);

  React.useEffect(() => {
    return () => {
      dispatch(activeMusic(null));
      dispatch(randomMusic(false));
    };
  }, []);

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    const heightScreen = document.documentElement.clientHeight - 270;
    const widthScreen = document.documentElement.clientWidth - 360;
    if (data.lastY > 100 && data.lastY > 0) {
      if (data.lastX > 0 && data.lastX > 10) {
        setCurrentPosition({
          xRate: 0,
          yRate: 100
        });
      }
    } else if (-heightScreen > data.lastY) {
      if (data.lastX > 0 && data.lastX > 10) {
        setCurrentPosition({
          xRate: 0,
          yRate: -heightScreen
        });
      } else {
        setCurrentPosition({
          xRate: -widthScreen,
          yRate: -heightScreen
        });
      }
    } else if (-widthScreen > data.lastX) {
      setCurrentPosition({
        xRate: -widthScreen,
        yRate: data.lastY
      });
    } else if (data.lastX > 0 && data.lastX > 10) {
      setCurrentPosition({
        xRate: 0,
        yRate: data.lastY
      });
    } else {
      setCurrentPosition({
        xRate: data.lastX,
        yRate: data.lastY
      });
    }
  };

  return (
    <Box>
      {(history.location.pathname.includes('/music_space') === false &&
        previousPath &&
        openMiniPlayer) ||
      isShowAudio ? (
        <Draggable
          onDrag={handleDrag}
          position={{ x: currentPosition.xRate, y: currentPosition.yRate }}
          cancel="img"
        >
          <Box className={classes.boxSlider}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (
                    history.location.pathname.includes('/music_space') === false
                  ) {
                    history.push('/music_space');
                    setOpenMiniPlayer(false);
                    dispatch(isShowMiniAudio(false));
                  }
                }}
              >
                <img
                  src={data[audioIndex]?.banner?.preview_url ?? logoEmso}
                  alt="preview_media"
                  className={classes.imageMedia}
                  onError={(e: any) => (e.target.src = logoEmso)}
                />
              </Box>
              <Box className={classes.boxAction}>
                <Box
                  sx={{
                    opacity: 0
                  }}
                  onClick={() => {
                    setOpenMiniPlayer(false);
                    dispatch(isShowMiniAudio(false));
                    setPreviousPath(false);
                    dispatch(
                      actionMusic({
                        audioIndex: audioIndex,
                        playAudio: false,
                        paused: false
                      })
                    );
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: 2,
                      right: 5,
                      color: '#fff',
                      cursor: 'pointer',
                      zIndex: 10
                    }}
                  >
                    <i className="fa-sharp fa-solid fa-xmark"></i>
                  </IconButton>
                </Box>
                <div
                  className={
                    data[audioIndex]?.name?.length > 30 ? classes.marquee : ''
                  }
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: '#fff'
                    }}
                    className={
                      data[audioIndex]?.name?.length > 30
                        ? classes.marqueeText
                        : ''
                    }
                  >
                    {data[audioIndex]?.name}
                  </Typography>
                </div>
                <Typography
                  variant="subtitle1"
                  component={'span'}
                  className={classes.truncate}
                  sx={{
                    fontSize: '14px',
                    color: '#717B85'
                  }}
                >
                  {data[audioIndex]?.page_owner?.title}
                </Typography>
                <Box>
                  <IconButton
                    aria-label="previous song"
                    onClick={handlePrevSong}
                    sx={{
                      color: '#fff',
                      opacity: audioIndex <= 0 ? '0.5' : '1',
                      cursor: audioIndex <= 0 ? 'inherit' : 'pointer'
                    }}
                  >
                    <FastRewindRounded
                      fontSize="medium"
                      sx={{ color: audioIndex <= 0 ? 'grey' : '' }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{ color: '#fff' }}
                    aria-label={paused ? 'play' : 'pause'}
                    onClick={() => handlePausePlayClick()}
                  >
                    {paused ? (
                      <PauseRounded sx={{ fontSize: '2rem' }} />
                    ) : (
                      <PlayArrowRounded sx={{ fontSize: '2rem' }} />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="next song"
                    onClick={handleNextSong}
                    sx={{ color: '#fff' }}
                  >
                    <FastForwardRounded fontSize="medium" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Draggable>
      ) : history.location.pathname.includes('/music_space') ? (
        <Box
          className={classes.boxPlayer}
          sx={{
            marginRight: marginRight,
            width: matches ? '100%' : widthHeader
          }}
        >
          <Grid container spacing={4}>
            <Grid item sm={3} md={4} lg={3} xl={3}>
              <Hidden smDown>
                <Box className={classes.coverImage}>
                  <CoverImage>
                    <img
                      alt="can't win - Chilling Sunday"
                      src={data[audioIndex]?.banner?.url ?? logoEmso}
                    />
                  </CoverImage>
                  <Box className={classes.boxItem}>
                    <Box sx={{ margin: '0px 10px', minWidth: 0 }}>
                      <Typography noWrap>
                        <b>{data[audioIndex]?.name}</b>
                      </Typography>
                      <Typography noWrap letterSpacing={-0.25}>
                        {data[audioIndex]?.page_owner?.title}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      {data[audioIndex]?.page_owner?.earn_money ? (
                        <IconButton
                          onClick={e => {
                            setOpenDialogDonate(e.currentTarget);
                          }}
                        >
                          <i
                            className="fa-light fa-star"
                            style={{ cursor: 'pointer', fontSize: '16px' }}
                          ></i>
                        </IconButton>
                      ) : null}
                      <IconButton
                        onClick={event => {
                          dispatch(
                            scrollSocialHeader({
                              position: 'hidden',
                              marginRight: '17px',
                              widthHeader: 'calc(100% - 67px)',
                              widthHeaderGlobal: 'calc(100% - 17px)'
                            })
                          );
                          setAnchorEl(event.currentTarget);
                          setIdMusic(data[audioIndex].id);
                          setDataMusic(data[audioIndex]);
                        }}
                      >
                        <i
                          className="fa-regular fa-ellipsis"
                          style={{
                            cursor: 'pointer',
                            fontSize: '16px'
                          }}
                        ></i>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Widget>
                <Box
                  sx={{
                    justifyContent: 'center',
                    marginTop: '10px'
                  }}
                  className={classes.itemFlex}
                >
                  <Tooltip
                    title={
                      isRandomMusic
                        ? 'Tắt phát ngẫu nhiên'
                        : 'Bật phát ngẫu nhiên'
                    }
                  >
                    <IconButton
                      aria-label="randomPlay"
                      onClick={() => {
                        setIsRandomMusic(!isRandomMusic);
                      }}
                    >
                      <i
                        className="fa-light fa-shuffle "
                        style={{
                          fontSize: '18px',
                          color: isRandomMusic
                            ? '#8D22C3'
                            : theme.palette.mode === 'dark'
                            ? '#fff'
                            : '#000000'
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>

                  <IconButton
                    aria-label="previous song"
                    onClick={handlePrevSong}
                    sx={{
                      opacity: audioIndex <= 0 ? '0.5' : '1',
                      cursor: audioIndex <= 0 ? 'inherit' : 'pointer',
                      marginLeft: '15px'
                    }}
                  >
                    <FastRewindRounded
                      fontSize="medium"
                      htmlColor={mainIconColor}
                      sx={{ color: audioIndex <= 0 ? 'grey' : '' }}
                    />
                  </IconButton>
                  <IconButton
                    aria-label={paused ? 'play' : 'pause'}
                    onClick={() => handlePausePlayClick()}
                    sx={{ margin: '0px 15px' }}
                  >
                    {paused ? (
                      <PauseRounded
                        sx={{ fontSize: '2.1rem' }}
                        htmlColor={mainIconColor}
                      />
                    ) : (
                      <PlayArrowRounded
                        sx={{ fontSize: '2.1rem' }}
                        htmlColor={mainIconColor}
                      />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="next song"
                    onClick={handleNextSong}
                    sx={{ marginRight: '15px' }}
                  >
                    <FastForwardRounded
                      fontSize="medium"
                      htmlColor={mainIconColor}
                    />
                  </IconButton>
                  <Tooltip
                    title={
                      isLoopMusic
                        ? 'Tắt phát lại một bài'
                        : 'Bật phát lại một bài'
                    }
                  >
                    <IconButton
                      aria-label="randomPlay"
                      onClick={() => {
                        setIsLoopMusic(!isLoopMusic);
                      }}
                    >
                      <i
                        className={
                          !isLoopMusic
                            ? 'fa-light fa-arrows-repeat'
                            : 'fa-light fa-arrows-repeat-1'
                        }
                        style={{
                          fontSize: '18px',
                          color: isLoopMusic
                            ? '#8D22C3'
                            : theme.palette.mode === 'dark'
                            ? '#fff'
                            : '#000000'
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <TinyText>{formatDuration(currentTime)}</TinyText>
                  <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={currentTime}
                    min={0}
                    step={1}
                    max={duration}
                    onChange={(_, value) => {
                      handleTimeSliderChange(value);
                    }}
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? '#fff'
                          : 'rgba(0,0,0,0.87)',
                      margin: '0px 8px',
                      height: 4,
                      '& .MuiSlider-thumb': {
                        width: 8,
                        height: 8,
                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                        '&:before': {
                          boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)'
                        },
                        '&:hover, &.Mui-focusVisible': {
                          boxShadow: `0px 0px 0px 8px ${
                            theme.palette.mode === 'dark'
                              ? 'rgb(255 255 255 / 16%)'
                              : 'rgb(0 0 0 / 16%)'
                          }`
                        },
                        '&.Mui-active': {
                          width: 20,
                          height: 20
                        }
                      },
                      '& .MuiSlider-rail': {
                        opacity: 0.28
                      }
                    }}
                  />
                  <TinyText>
                    -
                    {formatDuration(
                      duration - currentTime >= 0 ? duration - currentTime : 0
                    )}
                  </TinyText>
                </Box>
              </Widget>
            </Grid>
            <Grid item sm={3} md={4} lg={3} xl={3}>
              <Hidden smDown>
                <Box sx={{ height: '100%', marginRight: 3 }}>
                  <Stack
                    spacing={2}
                    className={classes.actionVolume}
                    direction="row"
                  >
                    <Hidden mdDown>
                      <IconButton size="small" disabled>
                        <i
                          className="fa-light fa-tv-music"
                          style={{
                            fontSize: '16px'
                          }}
                        ></i>
                      </IconButton>
                    </Hidden>

                    <i
                      className={`${
                        !mute && volume > 0
                          ? 'fa-light fa-volume'
                          : 'fa-light fa-volume-xmark'
                      }`}
                      style={{
                        color:
                          theme.palette.mode === 'dark'
                            ? '#fff'
                            : 'rgba(0,0,0,0.87)',
                        fontSize: '16px',
                        padding: '5px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        // setMute(!mute);
                        if (!mute) {
                          audioRef.current.volume = 0;
                          dispatch(getVolumeMusic({ volume: 0, mute: true }));
                        } else {
                          audioRef.current.volume = 0.5;
                          dispatch(
                            getVolumeMusic({ volume: 0.5, mute: false })
                          );
                        }
                      }}
                    ></i>

                    <Slider
                      aria-label="Volume"
                      value={volume}
                      max={1}
                      step={0.1}
                      min={0}
                      onChange={(_, value: any) => {
                        if (value === 0) {
                          dispatch(getVolumeMusic({ volume: 0, mute: true }));
                        } else {
                          dispatch(
                            getVolumeMusic({ volume: value, mute: false })
                          );
                        }
                        audioRef.current.volume = value;
                      }}
                      sx={{
                        color:
                          theme.palette.mode === 'dark'
                            ? '#fff'
                            : 'rgba(0,0,0,0.87)',

                        '& .MuiSlider-track': {
                          border: 'none'
                        },
                        '& .MuiSlider-thumb': {
                          width: 18,
                          height: 18,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? '#fff'
                              : 'rgba(0,0,0,0.87)',
                          '&:before': {
                            boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                          },
                          '&:after': {
                            width: 12,
                            height: 12
                          },
                          '&:hover, &.Mui-focusVisible, &.Mui-active': {
                            boxShadow: 'none'
                          }
                        },
                        width: { xs: '100%', md: '50%' },
                        maxWidth: '150px'
                      }}
                    />
                  </Stack>
                </Box>
              </Hidden>
            </Grid>
          </Grid>

          {anchorEl && (
            <MenuOption
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              open={open}
              idMusic={idMusic}
              dataMusic={dataMusic}
              setNoti={setNoti}
              setOpenSnackbar={setOpenSnackbar}
            />
          )}

          {openDonate && (
            <DialogPayment
              anchorEl={openDialogDonate}
              handleClosePayment={() => {
                setOpenDialogDonate(null);
              }}
              subsystem="Music"
              discountData={discountData}
              exchangeSelected={exchangeSelected}
              setExchangeSelected={setExchangeSelected}
              data={{
                title: ''
              }}
              infoDonate={data[audioIndex]}
              statusPayment="donate"
              setOpenDialogPaymentDetail={setOpenDialogPaymentDetail}
              action={data => handlePaymentDonate(data)}
              loading={loadingDonate}
              setLoading={setLoadingDonate}
            />
          )}
        </Box>
      ) : null}

      <audio
        key={data[audioIndex]?.id}
        loop={isLoopMusic ? true : false}
        ref={audioRef}
        src={data[audioIndex]?.media?.url}
        onLoadedData={handleLoadedData}
        onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
        onEnded={endedEvent}
        muted={mute}
      />
    </Box>
  );
};

export default MusicPlayerSlider;

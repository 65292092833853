import AppBar from '@mui/material/AppBar';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import bannerRK from 'src/assets/images/marketPlace/headerApp.png';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      grow: {
        flexGrow: 1
      },
      appBar: {
        boxShadow:
          '-85px -49px 3px -100px rgba(0,0,0,0.1),0px 0px 20px -3px rgba(0,0,0,0.1) !important',
        minHeight: '56px !important',
        maxHeight: '112px !important'
      },
      toolBar: {
        height: '56px !important',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '0px !important'
      }
    }),
  { index: 1 }
);

export default function PrimarySearchAppBar(props) {
  const { children, isBannerHidden, setIsBannerHidden } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isShow, setIsShow] = React.useState(true);
  const handleHide = () => {
    setIsShow(false);
    setIsBannerHidden(true);
  };
  //deeplink
  const handleBannerClick = () => {
    window.location.href = 'https://emso.page.link/start';
  };

  const responsiveRedux = useSelector((state: any) => state?.scrollReducer);
  const { marginRight, widthHeaderGlobal,paddingRight } = responsiveRedux || {};
  return (
    <div className={classes.grow}>
      <AppBar
        classes={{ root: classes.appBar }}
        position="fixed"
        color="inherit"
        sx={{
          paddingRight: `${paddingRight} !important`,
          width: widthHeaderGlobal,
          marginRight: marginRight,
          borderBottom:
            theme.palette.mode === 'dark'
              ? '1px solid #3e4042'
              : ' 1px solid #e5e7ec',
        }}
      >
        {isMobile && !isBannerHidden ? (
          <div
            style={{
              display: isShow ? 'block' : 'none',
              height: '56px',
              width: '100%',
              position: 'relative'
            }}
          >
            <img
              src={bannerRK}
              alt=""
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              onClick={handleBannerClick}
            />
            <div
              style={{
                position: 'absolute',
                top: '55%',
                left: '25px',
                transform: 'translate(-50%, -50%)'
              }}
              onClick={handleHide}
            >
              <i
                className="fal fa-times-circle"
                style={{ fontSize: '22px', color: '#ffff' }}
              ></i>
            </div>
          </div>
        ) : null}
        <Toolbar
          classes={{ root: classes.toolBar }}
          sx={{
            backgroundColor: 'background.primary',
            paddingLeft: '8px !important',
            paddingRight: '8px !important'
          }}
        >
          {children}
        </Toolbar>
      </AppBar>
    </div>
  );
}

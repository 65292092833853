import React from 'react';

import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { checkPasswordUser } from 'src/apis/socialUser';

import {
  getListMyPageMarketRequest,
  getProductOwner,
  loginFinanceMarket
} from 'src/store/action/socialProductAction';

import {
  default as avatarDefault,
  default as pageDefault
} from 'src/assets/images/avatar_default.jpg';
import { PATHS } from 'src/constants/paths';
import { WebkitScrollbarRGB } from 'src/constants/styles';

import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogConfirmPage from 'src/components/Dialog/DialogConfirmDelete';
import DialogVerifiedPass from 'src/components/Dialog/DialogVerifiedPass';
import ListButton from 'src/components/List/ListButton';

import DialogVerifyPageAndUser from './DialogVerifyPageAndUser';
import { getListConversationsChatPageReq } from 'src/store/action/socialChatAction';
import useWindowDimensions from 'src/hooks/useSizeScreen';
import { getOrderStats } from 'src/apis/socialMarketplace.api';
import { responsiveHeader } from 'src/store/action/headerFixed';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        width: '310px',
        height: '100%',
        backgroundColor: theme.palette.background.primary,
        position: 'fixed',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)',
        zIndex: 100
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 0px'
      },
      rootPage: {
        width: '100%',
        height: 'calc(100% - 172px)',
        overflowY: 'auto',
        '&:hover': {
          overflowY: 'auto'
        },
        ...WebkitScrollbarRGB({})
      },
      wrapButton: {
        padding: '0px 10px',
        width: '100%'
      },
      button: {
        marginTop: '12px',
        width: '70%'
      },
      title: {
        margin: '8px 14px 8px !important',
        fontSize: '18px !important'
      },
      wrapListButton: {
        width: '98%'
      }
    }),
  { index: 1 }
);

interface Props {
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
}

const MarketplaceLeft = (props: Props) => {
  const { setOpenSnackbar, setNoti } = props;

  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const { width } = useWindowDimensions();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const pageIdUrl: any = query.get('page_id');

  const { listPage: pageMarketList, hasMore } =
    useSelector((state: any) => state.productReducer.myPageMarket) ?? {};
  const productOwner = useSelector(
    (state: any) => state.productReducer.productOwner
  );
  const statusLoginFinance = useSelector(
    (state: any) => state.productReducer.statusLoginFinanceMarket
  );
  const listConversationsChatPage = useSelector(
    (state: any) => state.socialChatReducer.listConversationsChatPage
  );
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const pageInfo = useSelector((state: any) => state.pageReducer.info);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const [openDialogConfirmPage, setOpenDialogConfirmPage] =
    React.useState<any>(null);
  const [openVerifiedPass, setOpenVerifiedPass] = React.useState(false);
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [isNoti, setIsNoti] = React.useState(false);
  const [page, setPage] = React.useState(2);
  const [widthChat, setWidthChat] = React.useState<string>('65px');
  const [openDialogVerifyPageAndUser, setOpenDialogVerifyPageAndUser] =
    React.useState(false);
  const [totalMessage, setTotalMessage] = React.useState<number>(0);

  const pagesAdmin = pageMarketList.map((el: any) => ({
    id: el.id,
    username: el.username,
    avatar_icon: el.avatar_media
      ? el.avatar_media.show_url ??
        el.avatar_media.preview_url ??
        el.avatar_media.url
      : pageDefault,
    action: () => handleAction(el),
    label: el.title,
    styleLabel: { width: '180px' },
    checkbox: true
  }));

  const handleAction = el => {
    dispatch(
      getProductOwner({
        ...el,
        page_owner_id: el.id,
        title: el.title,
        avatar_icon: el.avatar_media
          ? el.avatar_media.show_url ??
            el.avatar_media.preview_url ??
            el.avatar_media.url
          : avatarDefault,
        description: 'Shop - Trang của bạn',
        ward: el.ward,
        username: el.username
      })
    );

    history.push(
      `/marketplace/shop/${
        !meInfo.identity_verification ||
        (!el?.identity_verification && el?.page_type === 'business') ||
        !el?.province?.id
          ? 'welcome_market'
          : 'product_hosting'
      }?page_id=${el.id}&page=1&type=all`
    );
  };

  const fetchOrderStats = async () => {
    let res = await getOrderStats(pageIdUrl);
    if (res.status === 200) {
      setIsNoti(res.data?.pending > 0);
    }
  };

  React.useEffect(() => {
    fetchOrderStats();
  }, []);

  React.useEffect(() => {
    if (pageIdUrl) {
      dispatch(getListConversationsChatPageReq(pageIdUrl, 10));
    }
  }, [pageIdUrl]);

  React.useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    if (
      match.params.key === 'chat' ||
      (match.params.key !== 'chat' && width <= 1300)
    )
      setWidthChat('65px');
    else setWidthChat('310px');
  }, [match.params.key, width]);

  React.useEffect(() => {
    if (listConversationsChatPage && listConversationsChatPage.length > 0) {
      const result = listConversationsChatPage.reduce(
        (total: any, item: any) => {
          if (item.countUnreadPage > 0) {
            return total + 1;
          }
          return total;
        },
        0
      );
      setTotalMessage(result);
    } else {
      setTotalMessage(0);
    }
  }, [JSON.stringify(listConversationsChatPage)]);

  const listMenuProduct = [
    {
      id: 'welcome_market',
      title: 'Chào mừng',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-regular fa-handshake" style={{ fontSize: '20px' }}></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/welcome_market?page_id=${productOwner?.page_owner_id}`
        );
      },
      style: { height: 36, width: 36 },
      position:
        !meInfo.identity_verification ||
        (!productOwner?.identity_verification &&
          productOwner?.page_type === 'business') ||
        !productOwner?.province?.id
          ? 'top'
          : ''
    },
    {
      id: 'home',
      title: 'Trang chủ',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-regular fa-house" style={{ fontSize: '20px' }}></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/home/store?page_id=${productOwner?.page_owner_id}`
        );
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'chat',
      title: 'Hộp thư',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-regular fa-message" style={{ fontSize: '20px' }}></i>
      ),
      numberNoti: totalMessage > 99 ? '99+' : totalMessage,

      style: { height: 36, width: 36 },
      action: () => {
        history.push(
          listConversationsChatPage.length > 0
            ? `/marketplace/shop/chat?page_id=${productOwner?.page_owner_id}&rid=${listConversationsChatPage[0]._id}`
            : `/marketplace/shop/chat?page_id=${productOwner?.page_owner_id}`
        );
      },
      position: 'top'
    },
    {
      id: 'notifications',
      title: 'Thông báo',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-regular fa-bell" style={{ fontSize: 22 }}></i>,
      style: { height: 36, width: 36 },
      stylePadding: {
        padding: '0px !important'
      },
      styleButton: {
        marginLeft: '0px !important'
      },
      accordion: true,
      itemChildren: [
        {
          id: 'noti_store',
          title: 'Thông báo cửa hàng',
          icon: <i style={{ fontSize: 22 }} className="fa-light fa-bell"></i>,
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(
              `/marketplace/shop/noti_store?page_id=${productOwner?.page_owner_id}&type_noti=orders_status`
            );
          },
          numberNoti: 10
        },
        {
          id: 'censorship',
          title: 'Nhật ký kiểm duyệt',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-flag"
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/censorship?page_id=${productOwner?.page_owner_id}`
            );
          }
        },
        {
          id: 'page_quality',
          title: 'Chất lượng trang',
          icon: <i style={{ fontSize: 22 }} className="fa-light fa-shield"></i>,
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(
              `/marketplace/shop/page_quality?page_id=${productOwner?.page_owner_id}`
            );
          }
        }
      ],

      position: 'top'
    },
    {
      id: 'product_order',
      title: 'Quản lý đơn hàng',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-regular fa-calendar-lines"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/product_order?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
        );
      },
      style: { height: 36, width: 36 },
      position: 'top',
      isNoti: isNoti
    },
    {
      id: 'product_shipping',
      title: 'Quản lý vận chuyển',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-regular fa-cart-flatbed-boxes"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/product_shipping?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=waiting_for_delivery&child=all`
        );
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'product_manage',
      title: 'Quản lý sản phẩm',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: (
        <i
          className="fa-regular fa-bag-shopping"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {},
      style: { height: 36, width: 36 },
      position: 'top',
      accordion: true,
      itemChildren: [
        {
          id: 'product_hosting',
          title: 'Danh sách sản phẩm',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-list" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/product_hosting?page_id=${productOwner?.page_owner_id}&page=1&type=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'create_product',
          title: 'Thêm sản phẩm mới',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-plus" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/create_product?page_id=${productOwner?.page_owner_id}`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'size_chart',
          title: 'Bảng quy đổi kích cỡ',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-light fa-game-board"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/size_chart?page_id=${productOwner?.page_owner_id}&page=1&limit=5`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'brand',
          title: 'Quản lý thương hiệu',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-tag" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/brand?page_id=${productOwner?.page_owner_id}&page=1&limit=5`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        }
      ]
    },
    {
      id: 'marketing',
      title: 'Kênh Marketing',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: <i className="fa-regular fa-user" style={{ fontSize: '20px' }}></i>,
      action: () => {
        // history.push('');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'campaign',
          title: 'Chương trình khuyến mãi',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-light fa-gift-card"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/campaign?page_id=${productOwner?.page_owner_id}&type=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'vouchers',
          title: 'Mã giảm giá của Shop',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-tags" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/vouchers?page_id=${productOwner?.page_owner_id}&page=1&type=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'flash_sale',
          title: 'Flash sale của Shop',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-store" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/flash_sale?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'shop_program',
          title: 'Chương trình của shop',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-sharp fa-light fa-tags"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/shop_program?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        }
        // mở lại
        // src/pages/Marketplaces/MarketplaceCenter/MarketplaceShop/MarketCombo/CUCombosPromotion.tsx
        // src/pages/Marketplaces/MarketplaceCenter/MarketplaceShop/MarketCombo/ManageCombo.tsx
        // ở đây
        // {
        //   id: 'combos_promotion',
        //   title: 'Combo khuyến mãi',
        //   styleTitle: { fontWeight: '400' },
        //   styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
        //   icon: (
        //     <i
        //       className="fa-light fa-rectangle-ad"
        //       style={{ fontSize: '20px' }}
        //     ></i>
        //   ),
        //   action: () => {
        //     history.push(
        //       `/marketplace/shop/combos_promotion?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
        //     );
        //   },
        //   style: { height: 36, width: 36 },
        //   position: 'top'
        // }
      ],
      position: 'top'
    },
    {
      id: 'manage_shop',
      title: 'Quản lý gian hàng',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: (
        <i
          className="fa-regular fa-bars-progress"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        // history.push('');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'shop_reviews',
          title: 'Đánh giá gian hàng',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-light fa-star-sharp-half-stroke"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/shop_reviews?page_id=${productOwner?.page_owner_id}&tab=all_reviews&rating_point=all_rating_point`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'emso_reviews',
          title: 'Đánh giá tín nhiệm EMSO',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-sharp fa-light fa-e"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/emso_reviews?page_id=${productOwner?.page_owner_id}`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        }
      ],
      position: 'top'
    },
    {
      id: 'finance',
      title: 'Tài chính',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-regular fa-wallet" style={{ fontSize: '20px' }}></i>
      ),
      action: () => {
        if (match.params.key !== 'finance' && !statusLoginFinance) {
          setOpenVerifiedPass(true);
        } else {
          history.push(
            `/marketplace/shop/finance?page_id=${productOwner?.page_owner_id}`
          );
        }
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'schedule_post',
      title: 'Bài viết đã lên lịch',
      icon: (
        <i
          className="fa-regular fa-calendar-lines-pen"
          style={{ fontSize: 22 }}
        ></i>
      ),
      style: {
        height: 36,
        width: 36
      },
      action: () => {
        history.push(
          `/marketplace/shop/schedule_post?page_id=${productOwner?.page_owner_id}`
        );
      },
      position: 'top'
    },
    {
      id: 'edit_page_info',
      title: 'Chỉnh sửa thông tin trang',
      icon: <i className="fa-regular fa-pen" style={{ fontSize: 22 }}></i>,
      style: {
        height: 36,
        width: 36
      },
      action: () => {
        history.push(
          `/marketplace/shop/edit_page_info?page_id=${productOwner?.page_owner_id}`
        );
      },
      position: 'top'
    },
    {
      id: 'settings',
      title: 'Cài đặt',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: <i className="fa-regular fa-gear" style={{ fontSize: 22 }}></i>,
      action: () => {},
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'settings',
          title: 'Chung',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: <i style={{ fontSize: 20 }} className="fa-light fa-gear"></i>,
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        },
        // {
        //   id: 'notifications',
        //   title: 'Thông báo',
        //   styleTitle: { fontWeight: '400' },
        //   styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
        //   icon: (
        //     <i style={{ fontSize: 20 }} className="fa-light fa-earth-asia"></i>
        //   ),
        //   style: { height: 36, width: 36 },
        //   action: () => {
        //     history.push(
        //       `/marketplace/shop/settings/notifications?page_id=${productOwner?.page_owner_id}`
        //     );
        //   },
        //   position: 'top'
        // },
        {
          id: 'admin_roles',
          title: 'Vai trò trên trang',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: <i style={{ fontSize: 20 }} className="fa-light fa-user"></i>,
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings/admin_roles?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        },
        // {
        //   id: 'account_holder_page',
        //   title: 'Chủ tài khoản Trang',
        //   styleTitle: { fontWeight: '400' },
        //   styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
        //   icon: (
        //     <i
        //       style={{ fontSize: 20 }}
        //       className="fa-light fa-building-columns"
        //     ></i>
        //   ),
        //   style: { height: 36, width: 36 },
        //   action: () => {
        //     history.push(
        //       `/marketplace/shop/settings/account_holder_page?page_id=${productOwner?.page_owner_id}`
        //     );
        //   },
        //   position: 'top'
        // },
        {
          id: 'people_and_other_pages',
          title: 'Người và trang khác',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i style={{ fontSize: 20 }} className="fa-light fa-user-group"></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings/people_and_other_pages?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        },
        {
          id: 'page_management_history',
          title: 'Nhật ký hoạt động',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{ fontSize: 20 }}
              className="fa-light fa-list-timeline"
            ></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings/page_management_history?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        },
        {
          id: 'page_management_message',
          title: 'Cài đặt hộp thư',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{ fontSize: 20 }}
              className="fa-light fa-message-dots"
            ></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings/page_management_message?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        }
      ],
      position: 'top'
    }
  ];

  const listMenuProductMorderator = [
    {
      id: 'home',
      title: 'Trang chủ',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-regular fa-house" style={{ fontSize: '20px' }}></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/home/store?page_id=${productOwner?.page_owner_id}`
        );
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'chat',
      title: 'Hộp thư',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-regular fa-message" style={{ fontSize: '20px' }}></i>
      ),
      numberNoti: totalMessage > 99 ? '99+' : totalMessage,

      style: { height: 36, width: 36 },
      action: () => {
        history.push(
          listConversationsChatPage.length > 0
            ? `/marketplace/shop/chat?page_id=${productOwner?.page_owner_id}&rid=${listConversationsChatPage[0]._id}`
            : `/marketplace/shop/chat?page_id=${productOwner?.page_owner_id}`
        );
      },
      position: 'top'
    },
    {
      id: 'notifications',
      title: 'Thông báo',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-regular fa-bell" style={{ fontSize: 22 }}></i>,
      style: { height: 36, width: 36 },
      stylePadding: {
        padding: '0px !important'
      },
      styleButton: {
        marginLeft: '0px !important'
      },
      accordion: true,
      itemChildren: [
        {
          id: 'noti_store',
          title: 'Thông báo cửa hàng',
          icon: <i style={{ fontSize: 22 }} className="fa-light fa-bell"></i>,
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(
              `/marketplace/shop/noti_store?page_id=${productOwner?.page_owner_id}&type_noti=orders_status`
            );
          },
          numberNoti: 10
        },
        {
          id: 'censorship',
          title: 'Nhật ký kiểm duyệt',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-flag"
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/censorship?page_id=${productOwner?.page_owner_id}`
            );
          }
        },
        {
          id: 'page_quality',
          title: 'Chất lượng trang',
          icon: <i style={{ fontSize: 22 }} className="fa-light fa-shield"></i>,
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(
              `/marketplace/shop/page_quality?page_id=${productOwner?.page_owner_id}`
            );
          }
        }
      ],

      position: 'top'
    },
    {
      id: 'product_order',
      title: 'Quản lý đơn hàng',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-regular fa-calendar-lines"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/product_order?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
        );
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'product_shipping',
      title: 'Quản lý vận chuyển',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-regular fa-cart-flatbed-boxes"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(
          `/marketplace/shop/product_shipping?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=waiting_for_delivery&child=all`
        );
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },

    {
      id: 'marketing',
      title: 'Kênh Marketing',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: <i className="fa-regular fa-user" style={{ fontSize: '20px' }}></i>,
      action: () => {
        // history.push('');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'vouchers',
          title: 'Mã giảm giá của Shop',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-tags" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/vouchers?page_id=${productOwner?.page_owner_id}&page=1&type=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'flash_sale',
          title: 'Flash sale của Shop',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i className="fa-light fa-store" style={{ fontSize: '20px' }}></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/flash_sale?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'shop_program',
          title: 'Chương trình của shop',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-sharp fa-light fa-tags"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/shop_program?page_id=${productOwner?.page_owner_id}&page=1&limit=5&status=all`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        }
      ],
      position: 'top'
    },
    {
      id: 'manage_shop',
      title: 'Quản lý gian hàng',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: (
        <i
          className="fa-regular fa-bars-progress"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        // history.push('');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'shop_reviews',
          title: 'Đánh giá gian hàng',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-light fa-star-sharp-half-stroke"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/shop_reviews?page_id=${productOwner?.page_owner_id}&tab=all_reviews&rating_point=all_rating_point`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        },
        {
          id: 'emso_reviews',
          title: 'Đánh giá tín nhiệm EMSO',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              className="fa-sharp fa-light fa-e"
              style={{ fontSize: '20px' }}
            ></i>
          ),
          action: () => {
            history.push(
              `/marketplace/shop/emso_reviews?page_id=${productOwner?.page_owner_id}`
            );
          },
          style: { height: 36, width: 36 },
          position: 'top'
        }
      ],
      position: 'top'
    },

    {
      id: 'schedule_post',
      title: 'Bài viết đã lên lịch',
      icon: (
        <i
          className="fa-regular fa-calendar-lines-pen"
          style={{ fontSize: 22 }}
        ></i>
      ),
      style: {
        height: 36,
        width: 36
      },
      action: () => {
        history.push(
          `/marketplace/shop/schedule_post?page_id=${productOwner?.page_owner_id}`
        );
      },
      position: 'top'
    },
    // {
    //   id: 'edit_page_info',
    //   title: 'Chỉnh sửa thông tin trang',
    //   icon: <i className="fa-regular fa-pen" style={{ fontSize: 22 }}></i>,
    //   style: {
    //     height: 36,
    //     width: 36
    //   },
    //   action: () => {
    //     history.push(
    //       `/marketplace/shop/edit_page_info?page_id=${productOwner?.page_owner_id}`
    //     );
    //   },
    //   position: 'top'
    // },
    {
      id: 'settings',
      title: 'Cài đặt',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: <i className="fa-regular fa-gear" style={{ fontSize: 22 }}></i>,
      action: () => {},
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'settings',
          title: 'Chung',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: <i style={{ fontSize: 20 }} className="fa-light fa-gear"></i>,
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        },
        // {
        //   id: 'account_holder_page',
        //   title: 'Chủ tài khoản Trang',
        //   styleTitle: { fontWeight: '400' },
        //   styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
        //   icon: (
        //     <i
        //       style={{ fontSize: 20 }}
        //       className="fa-light fa-building-columns"
        //     ></i>
        //   ),
        //   style: { height: 36, width: 36 },
        //   action: () => {
        //     history.push(
        //       `/marketplace/shop/settings/account_holder_page?page_id=${productOwner?.page_owner_id}`
        //     );
        //   },
        //   position: 'top'
        // },
        {
          id: 'people_and_other_pages',
          title: 'Người và trang khác',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i style={{ fontSize: 20 }} className="fa-light fa-user-group"></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings/people_and_other_pages?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        },

        {
          id: 'page_management_message',
          title: 'Cài đặt hộp thư',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{ fontSize: 20 }}
              className="fa-light fa-message-dots"
            ></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(
              `/marketplace/shop/settings/page_management_message?page_id=${productOwner?.page_owner_id}`
            );
          },
          position: 'top'
        }
      ],
      position: 'top'
    }
  ];

  const listMenuProductChat = listMenuProduct.map(item => {
    return {
      id: item.id,
      icon: item.icon,
      style: item.style,
      position: item.position,
      numberNoti: item?.numberNoti ? item.numberNoti : undefined
    };
  });
  const listMenuProductMorderatorChat = listMenuProductMorderator.map(item => {
    return {
      id: item.id,
      icon: item.icon,
      style: item.style,
      position: item.position,
      numberNoti: item?.numberNoti ? item.numberNoti : undefined
    };
  });

  const handleCheckLoginUser = async () => {
    try {
      let response = await checkPasswordUser({ current_password: password });
      if (response.status === 200) {
        dispatch(loginFinanceMarket('success'));
        setOpenVerifiedPass(false);
        setLoadingAction(false);
        history.push(
          `/marketplace/shop/finance?page_id=${productOwner?.page_owner_id}`
        );
      }
    } catch (err: any) {
      dispatch(loginFinanceMarket('fail'));
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message:
          err.response.status === 422
            ? 'Mật khẩu hiện tại không đúng.Vui lòng nhập lại mật khẩu của bạn!'
            : 'Xảy lỗi trong quá trình truy cập.Vui lòng thử lại sau!'
      });
      setLoadingAction(false);
    }
  };

  React.useEffect(() => {
    let { id, key } = match?.params;
    switch (match.path) {
      case PATHS.MARKETPLACE_SHOP: {
        if (key) {
          setSelectedIndex(key);
        }
        if (id && key !== 'home') {
          setSelectedIndex(id);
        }
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);

  React.useEffect(() => {
    if (!statusLoginFinance && match.params.key === 'finance') {
      setOpenVerifiedPass(true);
    } else {
      setOpenVerifiedPass(false);
    }
  }, [match.params.key, statusLoginFinance]);

  const funcLoad = () => {
    let pageLast = [...pagesAdmin].pop();
    let maxId = pageLast?.id;
    dispatch(
      getListMyPageMarketRequest({
        page: page,
        limit: 10,
        page_purpose: 'sell_products',
        lengthPage: pageMarketList.length
      })
    );
    setPage(prevPage => prevPage + 1);
  };

  const renderHeader = () => {
    return (
      <Grid container spacing={2}>
        <Grid container item justifyContent="space-between" alignItems="center">
          {(match.params?.key === 'chat' ||
            (match.params?.key !== 'chat' && width <= 1300)) &&
          widthChat !== '65px' ? (
            <Typography className={classes.title} fontWeight={700} variant="h5">
              Kênh người bán
            </Typography>
          ) : null}
          {match.params?.key !== 'chat' && width > 1300 && (
            <Typography className={classes.title} fontWeight={700} variant="h5">
              Kênh người bán
            </Typography>
          )}
        </Grid>
        {(match.params?.key === 'chat' ||
          (match.params?.key !== 'chat' && width <= 1300)) &&
          widthChat !== '65px' && (
            <Grid item xs={12} md={12} lg={12} sm={12}>
              {pageIdUrl === productOwner?.page_owner_id ? (
                <IconButtonOptions
                  name={productOwner?.title}
                  styleNameButton={{
                    width: '190px',
                    color: 'text.primary'
                  }}
                  avatar={productOwner?.avatar_icon}
                  description={productOwner?.description}
                  styleAvatar={{
                    height: 36,
                    width: 36,
                    marginRight: '12px'
                  }}
                  icon={
                    <i
                      style={{ fontSize: '16px' }}
                      className="fa-solid fa-caret-down"
                    ></i>
                  }
                  optionCheckDefault={productOwner?.page_owner_id}
                  openPopup={true}
                  style={{
                    margin: '0px 10px',
                    width: '290px',
                    minHeight: '64px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'button.primary.background'
                  }}
                  options={[[{ title: 'Trang' }].concat(pagesAdmin)]}
                  styleListMenu={{
                    minWidth: '280px',
                    height: '250px',
                    overflowY: 'scroll'
                  }}
                  stylePopup={{
                    zIndex: '1001',
                    width: '290px'
                  }}
                  styleDescription={{
                    color: 'text.primary',
                    opacity: theme.palette.mode === 'dark' ? '1' : '0.8'
                  }}
                  widthListItem="275px"
                  styleChecked="0px"
                  searchFeature={true}
                  actionSearch={el => {
                    dispatch(
                      getProductOwner({
                        ...el,
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Shop - Trang của bạn',
                        ward: el.ward,
                        username: el.username
                      })
                    );
                    history.push(
                      `/marketplace/shop/${
                        !meInfo.identity_verification ||
                        (!el?.identity_verification &&
                          el?.page_type === 'business') ||
                        !el?.province?.id
                          ? 'welcome_market'
                          : 'product_hosting'
                      }?page_id=${el.id}&page=1&type=all`
                    );
                  }}
                  infiniteScroll={true}
                  funcLoad={funcLoad}
                  hasMore={hasMore}
                  scrollableTarget="scroll-paper"
                  paramsSearchPage={{ page_purpose: 'sell_products' }}
                />
              ) : (
                <Skeleton height={80} width={290} sx={{ margin: '0 10px' }} />
              )}
            </Grid>
          )}
        {(match.params?.key === 'chat' ||
          (match.params?.key !== 'chat' && width <= 1300)) &&
          widthChat === '65px' && (
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Grid
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'6px'}
                sx={{
                  padding: '8px',
                  margin: '40px 8px 0 8px',
                  border: '1px solid #ccc',
                  height: '64px'
                }}
              >
                <img
                  alt=""
                  style={{
                    height: 36,
                    width: 36,
                    borderRadius: '50%'
                  }}
                  src={productOwner?.avatar_icon}
                />
              </Grid>
            </Grid>
          )}
        {match.params?.key !== 'chat' && width > 1300 && (
          <Grid item xs={12} md={12} lg={12} sm={12}>
            {pageIdUrl === productOwner?.page_owner_id ? (
              <IconButtonOptions
                name={productOwner?.title}
                styleNameButton={{
                  width: '190px',
                  color: 'text.primary'
                }}
                avatar={productOwner?.avatar_icon}
                description={productOwner?.description}
                styleAvatar={{
                  height: 36,
                  width: 36,
                  marginRight: '12px'
                }}
                icon={
                  <i
                    style={{ fontSize: '16px' }}
                    className="fa-solid fa-caret-down"
                  ></i>
                }
                optionCheckDefault={productOwner?.page_owner_id}
                openPopup={true}
                style={{
                  margin: '0px 10px',
                  width: '290px',
                  minHeight: '64px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'button.primary.background'
                }}
                options={[
                  [{ title: 'Trang' }].concat(
                    pagesAdmin.map((el: any) => ({
                      ...el,
                      checkbox: true
                    }))
                  )
                ]}
                styleListMenu={{
                  minWidth: '280px',
                  height: '250px',
                  overflowY: 'scroll'
                }}
                stylePopup={{
                  zIndex: '1001',
                  width: '290px'
                }}
                styleDescription={{
                  color: 'text.primary',
                  opacity: theme.palette.mode === 'dark' ? '1' : '0.8'
                }}
                widthListItem="275px"
                styleChecked="0px"
                searchFeature={true}
                actionSearch={el => {
                  dispatch(
                    getProductOwner({
                      ...el,
                      page_owner_id: el.id,
                      title: el.title,
                      avatar_icon: el.avatar_media?.show_url
                        ? el.avatar_media.show_url
                        : avatarDefault,
                      description: 'Shop - Trang của bạn',
                      ward: el.ward,
                      username: el.username
                    })
                  );
                  history.push(
                    `/marketplace/shop/${
                      !meInfo.identity_verification ||
                      (!el?.identity_verification &&
                        el?.page_type === 'business') ||
                      !el?.province?.id
                        ? 'welcome_market'
                        : 'product_hosting'
                    }?page_id=${el.id}&page=1&type=all`
                  );
                }}
                infiniteScroll={true}
                funcLoad={funcLoad}
                hasMore={hasMore}
                scrollableTarget="scroll-paper"
                paramsSearchPage={{ page_purpose: 'sell_products' }}
              />
            ) : (
              <Skeleton height={80} width={290} sx={{ margin: '0 10px' }} />
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  const renderBody = () => {
    let { key, id } = match?.params;
    switch (match.path) {
      case PATHS.MARKETPLACE_SHOP:
        switch (key) {
          default:
            return {
              bodyTop: (
                <>
                  <Box className={classes.wrapListButton}>
                    <ListButton
                      styleAccordionDetail={{ padding: '8px 5px 5px' }}
                      stylePadding={{
                        padding: '0px !important',
                        margin: '6px 0'
                      }}
                      item={
                        (match.params?.key === 'chat' &&
                          widthChat === '65px') ||
                        (match.params?.key !== 'chat' &&
                          width <= 1300 &&
                          widthChat === '65px')
                          ? listMenuProductChat.filter(
                              el => el.position === 'top'
                            )
                          : pageInfo?.page_relationship?.role === 'admin' ||
                            pageInfo?.page_relationship?.role === 'owner'
                          ? listMenuProduct.filter(el => el.position === 'top')
                          : listMenuProductMorderator.filter(
                              el => el.position === 'top'
                            )
                      }
                      specialAvatarStyle={{
                        minWidth:
                          (match.params?.key === 'chat' &&
                            widthChat === '65px') ||
                          (match.params?.key !== 'chat' &&
                            width <= 1300 &&
                            widthChat === '65px')
                            ? 50
                            : 35
                      }}
                      selectedItem={selectedIndex}
                      typeAction={'action'}
                    />
                  </Box>
                </>
              ),
              bodyCenter: null,
              bodyFooter: null
            };
        }
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        width:
          match.params?.key === 'chat'
            ? widthChat
            : width <= 1300
            ? widthChat
            : '310px'
      }}
      onMouseEnter={() => {
        dispatch(responsiveHeader('calc(100% - 360px)'))
        setWidthChat('310px');
      }}
      onMouseLeave={() => {
        setWidthChat('65px');
        dispatch(responsiveHeader('calc(100% - 115px)'));
      }}
    >
      {match.params?.id !== 'search' && (
        <div className={classes.headerPage}>{renderHeader()}</div>
      )}

      <div
        className={classes.rootPage}
        style={{
          overflowY:
            match.params?.key !== 'chat' && width < 1300 && widthChat === '65px'
              ? 'hidden'
              : 'auto'
        }}
      >
        {renderBody().bodyTop}
        {match.path !== PATHS.PAGE_CREATE && match.params?.id !== 'search' && (
          <div style={{ height: 10 }}></div>
        )}
        {renderBody().bodyCenter}
        {match.path === PATHS.MARKETPLACE_SHOP ? renderBody().bodyFooter : null}
      </div>

      {!!openDialogConfirmPage && (
        <DialogConfirmPage
          title="Cảnh báo"
          contentDialog={openDialogConfirmPage}
          open={!!openDialogConfirmPage}
          handleClose={() => setOpenDialogConfirmPage(null)}
          action={() => {
            if (!pageMarketList?.length) {
              history.push('/pages/creation');
              return;
            } else {
              history.push(
                `/page/${
                  productOwner?.username ?? productOwner?.page_owner_id
                }/edit_page_info`
              );
            }
          }}
          labelButton={!pageMarketList?.length ? 'Tạo trang ngay' : 'Cập nhật'}
        />
      )}

      {openVerifiedPass && (
        <DialogVerifiedPass
          open={openVerifiedPass}
          handleClose={() => {
            setOpenVerifiedPass(false);
            setOpenSnackbar(false);
          }}
          action={() => {
            setLoadingAction(true);
            handleCheckLoginUser();
          }}
          setPassword={setPassword}
          loading={loadingAction}
        />
      )}

      {openDialogVerifyPageAndUser && (
        <DialogVerifyPageAndUser
          open={openDialogVerifyPageAndUser}
          setOpen={setOpenDialogVerifyPageAndUser}
          productOwner={productOwner}
        />
      )}
    </div>
  );
};

export default MarketplaceLeft;

import {
  Box,
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addMemberGroupApi, deleteMemberGroupApi } from 'src/apis/socialGroup';
import { likeFollowPage } from 'src/apis/socialPages.api';
import ImageBannerGroup from 'src/assets/images/group_cover.png';
import DialogViewTagFriend from 'src/components/Dialog/DialogViewTagFriend';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import NameSocial from 'src/components/ProfileCardImage/NameSocial';
import { listVisibility } from 'src/constants/common';
import { buttonColor } from 'src/constants/styles';
import { handleTimeShow } from 'src/helpers/string';
import { updateFollowPage } from 'src/store/action/socialPostAction';
import MenuAction from './MenuAction';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { limitText } from 'src/common/limitText';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
import { getPageDetailReq } from 'src/store/action/socialPageAction';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  wrapTagFriend: {
    color: '#050505 !important',
    fontWeight: '500 !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  linkTextSecondary: {
    color: '#65676B !important',
    fontWeight: 500
  },
  tagText: {
    cursor: 'pointer',
    fontWeight: 600,
    '&:hover': { textDecoration: 'underline' }
  },
  cssText: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  suggestions: {
    margin: '16px 16px 0px !important'
  },
  textSuggestions: {
    color: '#65676b !important',
    paddingBottom: '10px !important'
  },
  lineClamp: {
    display: '-webkit-box !important',
    overflow: 'hidden',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical'
  },
  boxAlbum: {
    width: 'calc(100% - 32px)',
    paddingLeft: '20px',
    marginTop: '8px',
    cursor: 'pointer'
  }
}));

interface SocialPostInformartion {
  post?: any;
  type?: String;
  setPostData?: React.Dispatch<React.SetStateAction<any>> | any;
  setMediaData?: React.Dispatch<React.SetStateAction<any>> | any;
  embed?: any;
  typePost?: String;
  typeAction?: String;
  openDialogRating?: boolean;
  setOpenDialogRating?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setUpdateRating?: React.Dispatch<React.SetStateAction<any>> | any;
  setCreateRating?: React.Dispatch<React.SetStateAction<any>> | any;
  setRatingSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  itemRating?: any;
  ratingSelected?: any;
  idPost?: String | any;
  setValue?: React.Dispatch<React.SetStateAction<any>> | any;
  setFiles?: React.Dispatch<React.SetStateAction<any>> | any;
  valueRating?: any;
  typeShare?: String;
  fetchPostDetail?: any;
  setPostDetail?: any;
  roleGroupMember?: any;
  setListPostRating?: any;
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  pageInfo?: any;
  eventInfo?: any;
  courseInfo?: any;
  projectInfo?: any;
  setFollowPageId?: (id: string) => void;
  isWaitingPost?: boolean;
}

const Index: React.FC<SocialPostInformartion> = React.memo(props => {
  const {
    post,
    type,
    setPostData,
    setMediaData,
    embed,
    typePost,
    typeAction,
    setOpenDialogRating,
    setUpdateRating,
    setCreateRating,
    setRatingSelected,
    itemRating,
    ratingSelected,
    setValue,
    idPost,
    setFiles,
    valueRating,
    typeShare,
    setPostDetail,
    roleGroupMember,
    setListPostRating,
    setOpenSnackbar,
    setNoti,
    pageInfo,
    eventInfo,
    courseInfo,
    projectInfo,
    setFollowPageId,
    isWaitingPost
  } = props;

  const classes = useStyles();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openTagFriend, setOpenTagFriend] = React.useState<any>(false);
  const [actionGroup, setActionGroup] = React.useState<any>('parti');
  const [actionPage, setActionPage] = React.useState<any>('like');
  const dispatch = useDispatch();
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const roleGroup = useSelector(
    (state: any) => state.groupReducer?.groupRelationShip
  );
  const theme = useTheme();
  const history = useHistory();
  const page = useSelector((state: any) => state?.pageReducer.info) || {};

  const renderTextAction = () => {
    if (type === 'stream_home') {
      if (post.group) {
        if (
          post?.group?.group_relationship?.member === false &&
          post?.group?.group_relationship?.requested === false &&
          post?.group?.group_relationship?.admin === false
        ) {
          // setIsMember(-1);
          return (
            <span>
              &ensp;{actionGroup === 'parti' ? 'Tham gia ' : 'Đã tham gia'}{' '}
            </span>
          );
        }
      } else if (post.page) {
        if (
          post?.page_owner?.page_relationship?.following === false &&
          post?.page_owner?.page_relationship?.role !== 'admin' &&
          typeShare !== 'share-messenger'
        ) {
          return (
            <span>&ensp; {actionPage === 'like' ? 'Thích' : 'Đã thích'} </span>
          );
        }
      }
    }
    return null;
  };

  const renderTitleShare = () => {
    if (post?.account.gender === 'male') {
      return 'anh ấy';
    } else if (post?.account.gender === 'female') {
      return 'cô ấy';
    } else {
      return 'mình';
    }
  };

  const handleAction = async () => {
    let response: any;
    if (post.group) {
      if (actionGroup === 'parti') {
        response = await addMemberGroupApi(post.group?.id, {});
        if (response.status === 200) {
          setActionGroup('partied');
        }
      } else {
        response = await deleteMemberGroupApi(post.group?.id);
        if (response.status === 200) {
          setActionGroup('parti');
        }
      }
    } else if (post.page) {
      if (actionPage === 'like') {
        response = await likeFollowPage(post.page?.id, 'likes');
        if (response.status === 200) {
          setActionPage('liked');
        }
      } else {
        response = await likeFollowPage(post.page?.id, 'unlikes');
        if (response.status === 200) {
          setActionPage('like');
        }
      }
    }
  };

  const checkRoleGroup = (roleGroupMember: any) => {
    if (
      roleGroupMember?.admin ||
      (roleGroup?.admin && typeof roleGroupMember?.admin === 'undefined')
    ) {
      //phụ thuộc vào api trả về những khi tạo bài viết api trả về không có relationship nên dùng dữ liệu từ redux
      return 'Quản trị viên';
    } else if (
      roleGroupMember?.moderator ||
      (roleGroup?.moderator &&
        typeof roleGroupMember?.moderator === 'undefined')
    ) {
      return 'Người kiểm duyệt';
    } else if (
      roleGroupMember?.member ||
      (roleGroup?.member && typeof roleGroupMember?.member === 'undefined')
    ) {
      return 'Thành viên';
    }
    return null;
  };

  const handleOpenTag = () => {
    setOpenTagFriend(true);
  };

  const handleFollowPage = async () => {
    let response: any;
    response = await likeFollowPage(post.page?.id, 'follows');

    if (response.status === 200) {
      dispatch(updateFollowPage(post, type));
      dispatch(getPageDetailReq(post.page?.id));
      setFollowPageId && setFollowPageId(post.page?.id);
      setPostData &&
        setPostData(prev => ({
          ...prev,
          page: {
            ...prev?.page,
            page_relationship: {
              ...prev?.page?.page_relationship,
              following: true
            }
          }
        }));
    }
  };

  const ReportInfo = useSelector(
    (state: any) => state.socialReportReducer.listReport
  );

  const meInfo = useSelector((state: any) => state.meReducer.info);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setOpenPopper(false);
    setAnchorEl(null);
  };

  const renderAvatar = () => {
    if (
      (type === 'stream_home' || type === 'all_group') &&
      post &&
      post.group
    ) {
      return (
        <div style={{ position: 'relative', cursor: 'pointer' }}>
          <AvatarSocial
            avatarObj={post.group?.banner ?? ImageBannerGroup}
            style={{ width: 40, height: 40, borderRadius: 8, zIndex: 0 }}
            isPopup
            object={post?.group}
            isBanner
            setPostData={setPostData}
          />
          <AvatarSocial
            type="feed"
            avatarObj={post.account?.avatar_media ?? post.account?.avatar_url}
            style={{
              width: 28,
              height: 28,
              position: 'absolute',
              right: '10px',
              bottom: '0px',
              zIndex: 1
            }}
            isPopup
            object={post?.account}
            setPostData={setPostData}
          />
        </div>
      );
    } else if (post?.page_owner) {
      return (
        <AvatarSocial
          type="feed"
          avatarObj={
            post.page_owner?.avatar_media ?? post.page_owner?.avatar_url
          }
          style={{ width: 40, height: 40 }}
          isPopup
          object={post?.page_owner}
          setPostData={setPostData}
        />
      );
    } else if (post?.page && !post?.place) {
      return (
        <AvatarSocial
          type="feed"
          avatarObj={post.page?.avatar_media ?? post.page?.avatar_url}
          style={{ width: 40, height: 40 }}
          isPopup
          object={post?.page}
          setPostData={setPostData}
        />
      );
    } else {
      return (
        <AvatarSocial
          type="feed"
          avatarObj={post.account?.avatar_media ?? post.account?.avatar_url}
          style={{ width: 40, height: 40 }}
          isPopup={
            !post?.account_relationships?.blocking &&
            !post?.account_relationships?.blocked_by
          }
          object={post?.account}
          setPostData={setPostData}
        />
      );
    }
    // code cũ để debug
    // else if (type === 'sharePost' && post && post.group) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.account?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.account}
    //     />
    //   );
    // } else if (
    //   post.page_owner &&
    //   post.reblog &&
    //   type === 'page' &&
    //   rolePage !== 'admin'
    // ) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.account?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.account}
    //     />
    //   );
    // } else if (
    //   post.page_owner &&
    //   rolePageOwner === 'admin' &&
    //   post.reblog &&
    //   type === 'page' &&
    //   rolePage === 'admin'
    // ) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.page_owner?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.page_owner}
    //     />
    //   );
    // } else if (
    //   post.page_owner &&
    //   post.reblog &&
    //   rolePageOwner === 'admin' &&
    //   type === 'stream_home' &&
    //   rolePage === 'admin'
    // ) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.page_owner?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.page_owner}
    //     />
    //   );
    // } else if (
    //   post.page_owner &&
    //   post.reblog &&
    //   type === 'stream_home' &&
    //   rolePage !== 'admin'
    // ) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.account?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.account}
    //     />
    //   );
    // } else if (type === 'watch') {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.page_owner?.avatar_media ?? post.page?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.page_owner ?? post?.page}
    //     />
    //   );
    // } else if (post.page_owner && !post.reblog) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.page_owner?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.page_owner}
    //     />
    //   );
    // } else if (type === 'stream_group' && post && post.group) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.account?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.account}
    //     />
    //   );
    // } else if (
    //   // check sharing from watch to profile
    //   type === 'sharePost' &&
    //   post?.page &&
    //   !post?.page_owner &&
    //   match.path === PATHS.WATCH
    // ) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post?.page?.avatar_media?.url}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.account}
    //     />
    //   );
    // } else if (post?.post_type === 'event_shared' && post?.page) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post?.page?.avatar_media}
    //       style={{ width: 40, height: 40 }}
    //       isPopup
    //       object={post?.page}
    //     />
    //   );
    // } else if (post?.page && !post?.place && !post?.reblog) {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post?.page?.avatar_media?.url}
    //       style={{ width: 40, height: 40, marginTop: 8 }}
    //       isPopup
    //       object={post?.page}
    //     />
    //   );
    // } else {
    //   return (
    //     <AvatarSocial
    //       type="feed"
    //       avatarObj={post.account?.avatar_media}
    //       style={{ width: 40, height: 40, marginTop: 8 }}
    //       isPopup
    //       object={post?.account}
    //     />
    //   );
    // }
  };

  const renderNameOrObjectPopUp = (post: any, title: any) => {
    //code cũ để debug
    // if (post.group && (type === 'stream_home' || type === 'all_group')) {
    //   return title === 'name' ? post.group.title : post.group;
    // } else if (post.group && type === 'sharePost') {
    //   return title === 'name'
    //     ? post.account.display_name ?? post.account.username
    //     : post.account;
    // } else if (
    //   // if it's a page, show the page name
    //   type === 'sharePost' &&
    //   post.page &&
    //   !post.page_owner &&
    //   match.path === PATHS.WATCH
    // ) {
    //   return post?.page?.title;
    // } else if (
    //   post.reblog &&
    //   post.page_owner &&
    //   type === 'page' &&
    //   rolePage !== 'admin'
    // ) {
    //   return title === 'name'
    //     ? post.account.display_name
    //       ? post.account.display_name
    //       : post.account.username
    //     : post.account;
    // } else if (type === 'watch' || type === 'watch-search') {
    //   return title === 'name'
    //     ? post?.page_owner?.title ?? post?.page?.title
    //     : post.page_owner ?? post.page;
    // } else if (type === 'previewMedia') {
    //   if (title === 'name') {
    //     if (post?.page) {
    //       return post?.page?.title;
    //     } else {
    //       return post?.account?.display_name ?? post?.account?.username;
    //     }
    //   } else if (title === 'object') {
    //     return post?.page ? post?.page : post?.account;
    //   } else {
    //     return post.account;
    //   }
    // } else if (type === 'watch-selected') {
    //   if (title === 'name') {
    //     return post?.page_owner?.title ?? post?.page?.title;
    //   } else {
    //     return post.page_owner ?? post.page;
    //   }
    // } else if (
    //   post.reblog &&
    //   post.page_owner &&
    //   rolePageOwner === 'admin' &&
    //   type === 'page' &&
    //   rolePage === 'admin'
    // ) {
    //   return title === 'name' ? post.page_owner.title : post.page_owner;
    // } else if (
    //   post.reblog &&
    //   post.page_owner &&
    //   type === 'stream_home' &&
    //   rolePage !== 'admin'
    // ) {
    //   return title === 'name' ? post?.account?.display_name : post.account;
    // } else if (
    //   post.reblog &&
    //   post.page_owner &&
    //   type === 'stream_home' &&
    //   rolePageOwner === 'admin' &&
    //   rolePage === 'admin'
    // ) {
    //   return title === 'name' ? post.page_owner.title : post.page_owner;
    // } else if (post.page_owner && rolePageOwner === 'admin') {
    //   return title === 'name' ? post.page_owner.title : post.page_owner;
    // } else if (post?.post_type === 'event_shared' && post?.page) {
    //   return title === 'name' ? post?.page?.title : post?.page;
    // } else if (
    //   post?.page &&
    //   !post?.place &&
    //   !post?.reblog &&
    //   rolePage === 'admin'
    // ) {
    //   return title === 'name' ? post?.page?.title : post?.page;
    // } else if (
    //   post?.page &&
    //   post?.page?.id === post?.page_owner?.id &&
    //   rolePage !== 'admin'
    // ) {
    //   return title === 'name' ? post?.page?.title : post?.page;
    // } else if (
    //   post?.album &&
    //   post?.album?.category !== 'avatar' &&
    //   post?.album?.category !== 'banner'
    // ) {
    //   return title === 'name'
    //     ? post?.page?.title ?? post?.account?.display_name
    //     : post?.page ?? post?.account;
    // } else {
    //   return title === 'name'
    //     ? post?.account?.display_name
    //       ? post?.account?.display_name
    //       : post?.account?.username
    //     : post.account;
    // }
    if (title === 'name') {
      //bài ghim
      if (typePost === 'pinned') {
        if (post.group && (type === 'stream_home' || type === 'all_group')) {
          return post?.group?.title;
        } else if (post?.page_owner) {
          return limitText(post?.page_owner?.title, 15);
        } else if (post?.page && !post?.place) {
          return limitText(post?.page?.title, 15);
        } else {
          return post?.account?.display_name ?? post?.account?.username;
        }
      } else {
        if (post.group && (type === 'stream_home' || type === 'all_group')) {
          return post?.group?.title;
        } else if (post?.page_owner) {
          return post?.page_owner?.title;
        } else if (post?.page && !post?.place) {
          return post?.page?.title;
        } else {
          return post?.account?.display_name ?? post?.account?.username;
        }
      }
    } else {
      if (post.group && (type === 'stream_home' || type === 'all_group')) {
        return post?.group;
      } else if (post?.page_owner) {
        return post?.page_owner;
      } else if (post?.page && !post?.place) {
        return post?.page;
      } else {
        return post?.account;
      }
    }
  };

  const renderTextPrimary = () => {
    let textDescription: any;
    if (post.status_activity) {
      if (post.status_activity.parent) {
        textDescription = (
          <span style={{ color: '#65676B' }}>
            &nbsp;
            <img
              style={{ width: 16, height: 16 }}
              src={post.status_activity.url}
              alt=""
            />{' '}
            {post.status_activity.parent.name
              .slice(0, post.status_activity.parent.name.length)
              .toLowerCase()}
            &nbsp;
            {post.status_activity.name}
          </span>
        );
      } else if (post.tagable_page) {
        textDescription = (
          <span style={{ color: '#65676B' }}>
            &nbsp;
            <img
              style={{ width: 16, height: 16 }}
              src={post?.status_activity?.url}
              alt=""
            />{' '}
            {post.status_activity.name
              .slice(0, post.status_activity.name.length)
              .toLowerCase()}
            &nbsp;
            <NameSocial
              post={post}
              name={post.tagable_page.title}
              object={post.tagable_page}
              style={{
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
              styleTextHeader={{ wordBreak: 'break-word' }}
              isPopup
            />
          </span>
        );
      } else if (type !== 'all_group') {
        textDescription = (
          <span style={{ color: '#65676B' }}>
            &nbsp;đang&nbsp;
            <img
              style={{ width: 16, height: 16, marginBottom: -3 }}
              src={post.status_activity.url}
              alt=""
            />
            &nbsp;cảm thấy {post.status_activity.name}
          </span>
        );
      }
    }

    if (post.post_type === 'livestream') {
      textDescription = (
        <span style={{ color: '#65676B' }}>
          &nbsp;
          {post.processing === 'done'
            ? 'đang'
            : post.processing === 'ended_live'
            ? 'đã'
            : null}{' '}
          phát trực tiếp
        </span>
      );
    }
    if (post.post_type === 'question') {
      textDescription = (
        <span style={{ color: '#65676B' }}>&nbsp;đã đặt một câu hỏi</span>
      );
    }

    if (post.post_type === 'target') {
      textDescription = (
        <span style={{ color: '#65676B' }}>&nbsp;đã đặt một mục tiêu</span>
      );
    }

    if (post?.status_target?.target_status === 'completed') {
      textDescription = (
        <span style={{ color: '#65676B' }}>
          &nbsp;đã hoàn thành một mục tiêu
        </span>
      );
    }

    if (
      post?.shared_recruit &&
      type !== 'all_group' &&
      type !== 'stream_home'
    ) {
      textDescription = (
        <>
          {textDescription}
          <span style={{ color: '#65676B' }}>
            &nbsp;đã chia sẻ tin tuyển dụng
          </span>
        </>
      );
    }
    if (post?.shared_music && type !== 'all_group' && type !== 'stream_home') {
      textDescription = (
        <>
          {textDescription}
          <span style={{ color: '#65676B' }}>&nbsp;đã chia sẻ một bài hát</span>
        </>
      );
    }
    if (post?.shared_group && type !== 'all_group' && type !== 'stream_home') {
      textDescription = (
        <span style={{ color: '#65676B' }}>&nbsp;đã chia sẻ nhóm</span>
      );
    }
    if (
      post?.share_product_or_course &&
      type !== 'all_group' &&
      type !== 'stream_home'
    ) {
      textDescription = (
        <>
          {textDescription}
          <span style={{ color: '#65676B' }}>
            &nbsp;đã chia sẻ một{' '}
            {post?.share_product_or_course.entity_type === 'Product'
              ? 'sản phẩm'
              : 'khoá học'}
          </span>
        </>
      );
    }
    if (post?.shared_event && type !== 'all_group' && type !== 'stream_home') {
      textDescription = (
        <>
          {textDescription}
          <span style={{ color: '#65676B' }}>&nbsp;đã chia sẻ một sự kiện</span>
        </>
      );
    }
    if (post?.shared_page && type !== 'all_group' && type !== 'stream_home') {
      textDescription = (
        <span style={{ color: '#65676B' }}>&nbsp;đã chia sẻ trang</span>
      );
    }
    if (
      post?.shared_project &&
      type !== 'all_group' &&
      type !== 'stream_home'
    ) {
      textDescription = (
        <>
          {textDescription}
          <span style={{ color: '#65676B' }}>
            &nbsp;đã chia sẻ dự án gọi vốn
          </span>
        </>
      );
    }

    if (
      post.post_type === 'account_avatar' ||
      post.post_type === 'page_avatar'
    ) {
      textDescription = (
        <span style={{ color: '#65676B' }}>
          &nbsp;đã cập nhật ảnh đại diện{' '}
        </span>
      );
    }

    if (
      post.post_type === 'page_banner' ||
      post.post_type === 'account_banner'
    ) {
      textDescription = (
        <span style={{ color: '#65676B' }}>
          &nbsp;đã cập nhật ảnh bìa của họ
        </span>
      );
    }

    if (post.post_type === 'group_banner' && type === 'stream_group') {
      textDescription = (
        <span style={{ color: '#65676B' }}>
          &nbsp;đã cập nhật ảnh bìa cho nhóm
        </span>
      );
    }
    if (
      post.post_type === 'group_banner' &&
      (type === 'stream_home' || type === 'all_group')
    ) {
      textDescription = (
        <span style={{ color: '#65676B' }}>&nbsp;đã được cập nhật ảnh bìa</span>
      );
    }

    if (post.poll) {
      textDescription = post?.status_activity ? (
        <span style={{ color: '#65676B' }}>
          &nbsp;đang&nbsp;
          <img
            style={{ width: 16, height: 16, marginBottom: -3 }}
            src={post.status_activity.url}
            alt=""
          />
          &nbsp;cảm thấy {post.status_activity.name}
        </span>
      ) : (
        <span style={{ color: '#65676B' }}>
          &nbsp;đã tạo một cuộc thăm dò ý kiến
        </span>
      );
    }

    if (post.place && type !== 'all_group') {
      textDescription = (
        <>
          {textDescription}
          {textDescription ? (
            <span style={{ color: '#65676B' }}>&nbsp;tại</span>
          ) : (
            <span style={{ color: '#65676B' }}>&nbsp;đang ở</span>
          )}
          &nbsp;
          <NameSocial name={post.place.title} isPopup object={post.place} />
        </>
      );
    }
    if (post?.mentions?.length && type !== 'all_group') {
      textDescription = (
        <>
          {textDescription}
          &nbsp;cùng với&nbsp;
          {post.mentions.length === 1 ? (
            post?.mentions?.[0]?.account_relationships?.blocking ||
            post?.mentions?.[0]?.account_relationships?.blocked_by ? (
              <Typography component={'span'}>
                {post.mentions[0].display_name}
              </Typography>
            ) : (
              <NameSocial
                name={
                  post.mentions[0].display_name ?? post.mentions[0].username
                }
                isPopup
                object={post.mentions[0]}
              />
            )
          ) : post.mentions.length === 2 ? (
            <>
              {post?.mentions?.[0]?.account_relationships?.blocking ||
              post?.mentions?.[0]?.account_relationships?.blocked_by ? (
                <Typography component={'span'}>
                  {post.mentions[0].display_name}
                </Typography>
              ) : (
                <NameSocial
                  name={
                    post.mentions[0].display_name ?? post.mentions[0].username
                  }
                  isPopup
                  object={post.mentions[0]}
                />
              )}
              &nbsp;và&nbsp;
              {post?.mentions?.[1]?.account_relationships?.blocking ||
              post?.mentions?.[1]?.account_relationships?.blocked_by ? (
                <Typography component={'span'}>
                  {post.mentions[1].display_name}
                </Typography>
              ) : (
                <NameSocial
                  name={
                    post.mentions[1].display_name ?? post.mentions[1].username
                  }
                  isPopup
                  object={post.mentions[1]}
                />
              )}
            </>
          ) : (
            <>
              {post?.mentions?.[0]?.account_relationships?.blocking ||
              post?.mentions?.[0]?.account_relationships?.blocked_by ? (
                <Typography component={'span'}>
                  {post.mentions[0].display_name}
                </Typography>
              ) : (
                <NameSocial
                  name={
                    post.mentions[0].display_name ?? post.mentions[0].username
                  }
                  isPopup
                  object={post.mentions[0]}
                />
              )}
              &nbsp;và&nbsp;
              <span onClick={handleOpenTag} className={classes.tagText}>
                {post.mentions.length - 1} người khác.
              </span>
            </>
          )}
        </>
      );
    }
    if (post.event && post.post_type === 'event_shared') {
      textDescription = (
        <span style={{ color: '#747474' }}>
          &nbsp;đã tạo một&nbsp;
          <span style={{ fontWeight: '600' }}>Sự kiện</span>
        </span>
      );
    }
    if (post.project && post.post_type === 'project_shared') {
      textDescription = (
        <span style={{ color: '#747474' }}>
          &nbsp;đã tạo một&nbsp;
          <span style={{ fontWeight: '600' }}>Dự án</span>
        </span>
      );
    }
    if (post.recruit && post.post_type === 'recruit_shared') {
      textDescription = (
        <span style={{ color: '#747474' }}>
          &nbsp;đã đăng một tin &nbsp;
          <span style={{ fontWeight: '600' }}>Tuyển dụng</span>
        </span>
      );
    }
    //show data when post status in other user
    if (
      post?.target_account &&
      !post?.status_activity &&
      !post?.place &&
      !post?.mentions?.length
    ) {
      textDescription = (
        <>
          <ArrowRightIcon sx={{ marginBottom: '-6px' }} />
          <Typography component={'span'}>
            <NameSocial
              name={post?.target_account?.display_name}
              isPopup
              object={post?.target_account}
            />
          </Typography>
        </>
      );
    }
    if (post) {
      return (
        <div
          style={{
            width: 'calc(100% - 30px)',
            display:
              type === 'watch-search' || type === 'watch'
                ? 'flex'
                : 'inline-block',
            alignItems: 'center'
          }}
          className={typePost === 'pinned' ? classes.lineClamp : ''}
        >
          <NameSocial
            post={post}
            type="previewMedia"
            name={renderNameOrObjectPopUp(post, 'name')}
            target_name={
              type === 'watch' ||
              type === 'previewMedia' ||
              (type === 'sharePost' && !post?.group) ||
              ['admin', 'owner'].includes(rolePage.role) ||
              roleGroup?.admin ||
              post?.post_type === 'event_shared'
                ? null
                : post.target_account?.display_name ||
                  (post?.page?.id !== post?.page_owner?.id && !post.place
                    ? post?.page?.title
                    : undefined) ||
                  (type === 'sharePost' && post?.group)
                ? post?.group?.title
                : null
            }
            object_target={
              type === 'watch'
                ? null
                : post?.group ||
                  (post?.page?.id !== post?.page_owner?.id && post?.page)
            }
            object={renderNameOrObjectPopUp(post, 'object')}
            style={{
              fontWeight: 500,
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}
            styleTextHeader={{ wordBreak: 'break-word' }}
            setPostData={setPostData}
            isPopup={
              !post?.account_relationships?.blocking &&
              !post?.account_relationships?.blocked_by
            }
          />
          {post?.album && typePost === 'pinned' ? (
            <div>
              <Typography>
                album:{' '}
                <span
                  style={{
                    color: 'text.primary',
                    fontWeight: '600',
                    fontSize: 15
                  }}
                >
                  {limitText(post?.album?.title, 15)}
                </span>
              </Typography>
            </div>
          ) : null}
          {textDescription}
          {!post?.page?.page_relationship?.following &&
            (type === 'watch' ||
              type === 'watch-selected' ||
              type === 'watch-search') && (
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '4px'
                }}
              >
                <Typography sx={{ display: 'flex' }}>.</Typography>
                <Typography
                  onClick={handleFollowPage}
                  sx={{
                    textTransform: 'none',
                    padding: '4px',
                    color: buttonColor.backgroundColor,
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }
                  }}
                >
                  Theo dõi
                </Typography>
              </Box>
            )}
          <div
            onClick={handleAction}
            style={{
              color: !(actionGroup === 'partied' || actionPage === 'liked')
                ? buttonColor.backgroundColor
                : '#65676B',
              fontWeight:
                actionGroup === 'partied' || actionPage === 'liked' ? 500 : 600,
              display: 'inline'
            }}
            className={classes.cssText}
          >
            {renderTextAction()}
          </div>
        </div>
      );
    }
  };

  const renderVisibilityIcon = () => {
    const renderIcon = listVisibility?.find(
      (el: any) => el.key === post.visibility
    );

    return (
      <Tooltip title={renderIcon?.label ?? ''}>
        <i className={renderIcon?.icon}></i>
      </Tooltip>
    );
  };
  function checkVideo(array: any) {
    let videoArr = [];
    videoArr = array.filter((el: any) => el.type === 'video');
    if (videoArr.length === array.length) return true;
    else return false;
  }

  const handleOnClickLink = () => {
    let link;
    if (post?.post_type === 'watch') {
      // Nếu đã mở dialog thì không điều hướng
      if (query.get('v')) link = null;
      else link = `/watch?v=${post?.media_attachments?.[0]?.id}`;
    } else if (post.group?.id) {
      link = `/group/${post.group?.id}/post/${post?.id}`;
    } else {
      link = `/${
        post.page?.username ??
        post.page?.id ??
        post.group?.username ??
        post.account?.username ??
        post.account?.id
      }/posts/${post?.media_attachments?.[0]?.status_id ?? post.id}`;
    }

    if (link) history.push(link);
  };

  const renderPosterName = () => {
    if (
      post?.page?.page_relationship?.role === 'admin' ||
      post?.page?.page_relationship?.role === 'moderator'
    ) {
      return post?.account?.display_name ?? post?.account?.username;
    }
    return post?.page_owner?.title;
  };

  const renderTextSecondary = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {post.group &&
          (post?.group?.post_permission_setting !== 'ADMIN_ONLY' ||
            post.visibility === 'public') &&
          post.visibility !== 'pending' &&
          checkRoleGroup(roleGroupMember) &&
          type !== 'sharePost' &&
          type !== 'stream_home' &&
          type !== 'all_group' && (
            <>
              <span
                style={{
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
                  color: buttonColor.backgroundColor,
                  padding: '1px 5px',
                  borderRadius: '5px'
                }}
              >
                {post.group && checkRoleGroup(roleGroupMember)}
              </span>{' '}
              &nbsp;
            </>
          )}
        <NameSocial
          post={post}
          name={
            post.group &&
            (type === 'stream_home' || type === 'all_group') && (
              <span
                style={{
                  fontWeight: 500,
                  marginRight: 6,
                  fontSize: 13,
                  color: theme.palette.mode === 'dark' ? '#b0b3b8' : '#65676b',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {post.account.display_name
                  ? post.account.display_name
                  : post?.account?.username}
              </span>
            )
          }
          object={
            post.group &&
            (type === 'stream_home' || type === 'all_group') &&
            post.account
          }
          style={{
            fontWeight: 500,
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            },
            marginTop: '-1px'
          }}
          isPopup
        />
        <Typography variant="subtitle1" sx={{ fontSize: '0.85rem' }}>
          {typePost !== 'pinned' &&
          post?.page &&
          post?.page_owner &&
          ['admin', 'owner'].includes(
            post.page_owner.page_relationship?.role
          ) &&
          ['admin', 'owner'].includes(rolePage.role) &&
          (type === 'page' || type === 'previewMedia') ? (
            <>
              {`Người đăng: `}
              <Link
                className={classes.linkTextSecondary}
                href={`/user/${post.account?.username ?? post.account.id}`}
                underline="hover"
              >
                {renderPosterName()}
              </Link>
              <Tooltip title="Chỉ những người quản lý Trang này mới xem được ai đã đăng">
                <i
                  className="fa-solid fa-circle-info"
                  style={{ margin: '0px 5px 0px 3px' }}
                ></i>
              </Tooltip>
            </>
          ) : null}

          <Link
            sx={{ color: 'text.secondary', cursor: 'pointer' }}
            underline={type === 'rating' ? 'none' : 'hover'}
            onClick={
              type === 'rating' ||
              typeAction === 'scheduled_action' ||
              isWaitingPost
                ? () => {}
                : handleOnClickLink
            }
          >
            {handleTimeShow(
              post?.scheduled_at ?? post?.backdated_time ?? post?.created_at
            )}
            &nbsp;
          </Link>
          <span style={{ textAlign: 'center' }}>
            &nbsp;
            {post?.group ? (
              post.group?.is_private ? (
                <i className="fas fa-users"></i>
              ) : (
                <i className="fas fa-globe-asia"></i>
              )
            ) : (
              renderVisibilityIcon()
            )}
          </span>
        </Typography>
      </div>
    );
  };

  return (
    <>
      {typeShare !== 'share-messenger' ? (
        ((post?.page?.page_relationship?.following === false &&
          post?.page?.page_relationship?.role !== 'admin') ||
          (post?.group?.group_relationship?.member === false &&
            post?.group?.group_relationship?.admin === false) ||
          (post?.account?.relationships?.following === false &&
            !post?.page &&
            !post?.group)) &&
        type === 'stream_home' ? (
          <div className={classes.suggestions}>
            <Typography className={classes.textSuggestions}>
              Gợi ý cho bạn
            </Typography>
            <Divider />
          </div>
        ) : post &&
          post?.album &&
          post?.album?.category !== 'avatar' &&
          post?.album?.category !== 'banner' ? (
          <div
            className={classes.boxAlbum}
            onClick={() => {
              if (post?.group) {
                history.push(
                  `/group/${post?.group?.id}/file/albums/albums_detail?group_selected=${post?.group?.id}&&album_selected=${post?.album?.id}`
                );
              } else if (post?.page) {
                history.push(
                  `/page/${post?.page?.id}/file/albums/albums_detail?page_selected=${post?.page?.id}&&album_selected=${post?.album?.id}`
                );
              } else {
                history.push(
                  `/user/${post?.account?.id}/file/albums/albums_detail?user_selected=${post?.account?.id}&&album_selected=${post?.album?.id}`
                );
              }
            }}
          >
            {typePost === 'pinned' ? null : (
              <>
                Album &nbsp;
                <Typography sx={{ color: 'text.primary', fontSize: 15 }}>
                  <Typography
                    sx={{
                      color: 'text.primary',
                      fontWeight: '600',
                      fontSize: 15
                    }}
                  >
                    {post.album?.title}
                  </Typography>
                </Typography>
                <Divider sx={{ marginTop: '8px' }} />
              </>
            )}
          </div>
        ) : post &&
          post?.replies?.length &&
          post?.replies?.find(el => el.suggested_by === 'friend') ? (
          <div
            style={{ width: 'calc(100% - 32px)', margin: '16px 16px 0px 16px' }}
          >
            <Typography sx={{ fontSize: 15, color: 'text.primary' }}>
              <Link
                sx={{ color: 'text.primary', fontWeight: 600 }}
                href={`/user/${post?.replies?.map(
                  el => el.account.username ?? el.account.id
                )}`}
                underline="hover"
              >
                {post?.replies[0]?.page_owner
                  ? post?.replies[0]?.page_owner.title
                  : post?.replies[0]?.account.display_name
                  ? post?.replies[0]?.account.display_name
                  : post?.account?.username}
              </Link>{' '}
              đã bình luận&nbsp;
            </Typography>
            <Divider sx={{ marginTop: '8px' }} />
          </div>
        ) : type === 'stream_home' && post ? (
          meInfo.id !== post.account.id &&
          post?.reblog?.post_type === 'watch' ? (
            <div
              style={{
                width: 'calc(100% - 32px)',
                margin: '16px 16px 0px 16px'
              }}
            >
              <span style={{ color: '#747474', fontSize: 15 }}>
                <Link
                  sx={{ color: 'text.primary', fontWeight: 600 }}
                  href={`/user/${post?.account?.username ?? post?.account?.id}`}
                  underline="hover"
                >
                  {post?.account?.display_name
                    ? post.account.display_name
                    : post?.account?.username}
                </Link>{' '}
                đã chia sẻ 1 Video&nbsp;
              </span>
              <Divider sx={{ marginTop: '8px' }} />
            </div>
          ) : meInfo.id !== post.account.id &&
            post?.post_type !== 'watch' &&
            post?.post_type !== 'moment' &&
            post?.media_attachments.length > 0 ? (
            <div
              style={{
                width: 'calc(100% - 32px)',
                margin: '16px 16px 0px 16px'
              }}
            >
              <Typography style={{ fontSize: 15 }}>
                <Link
                  sx={{ fontWeight: 600, color: 'text.primary' }}
                  href={
                    post?.page
                      ? `/page/${post?.page?.username ?? post?.page?.id}`
                      : `/user/${post?.account?.username ?? post?.account?.id}`
                  }
                  underline="hover"
                >
                  {post?.page ? post?.page?.title : post?.account?.display_name}
                </Link>{' '}
                đã thêm {post?.media_attachments.length}{' '}
                {checkVideo(post?.media_attachments) === true ? 'video' : 'ảnh'}{' '}
                mới{' '}
                {post?.group ? (
                  <span>
                    trong nhóm{' '}
                    <Link
                      sx={{ color: 'text.primary', fontWeight: 600 }}
                      href={`/group/${
                        post?.group?.username ?? post?.group?.id
                      }`}
                      underline="hover"
                    >
                      {post?.group?.title}
                    </Link>{' '}
                  </span>
                ) : null}
              </Typography>
              <Divider sx={{ marginTop: '8px' }} />
            </div>
          ) : meInfo.id !== post.account.id &&
            !post?.page_owner &&
            post?.reblog &&
            post?.reblog?.post_type !== 'watch' ? (
            <div
              style={{
                width: 'calc(100% - 32px)',
                margin: '16px 16px 0px 16px'
              }}
            >
              <span style={{ color: '#747474', fontSize: 15 }}>
                <Link
                  sx={{
                    color: 'text.primary',
                    fontWeight: 600
                  }}
                  href={`/user/${post?.account?.username ?? post?.account?.id}`}
                  underline="hover"
                >
                  {post?.account?.display_name
                    ? post.account.display_name
                    : post?.account?.username}
                </Link>{' '}
                đã chia sẻ bài viết của
                {post?.reblog?.group ? ' nhóm ' : null}{' '}
                <span>
                  {post?.reblog?.account.id === post?.account.id ? (
                    renderTitleShare()
                  ) : (
                    <Link
                      sx={{
                        color: 'text.primary',
                        fontWeight: 600
                      }}
                      href={
                        post?.reblog?.group
                          ? `/group/${post?.group?.username ?? post?.group?.id}`
                          : post?.reblog?.page
                          ? `/page/${
                              post?.reblog?.page?.username ??
                              post?.reblog?.page?.id
                            }`
                          : `/user/${
                              post?.reblog?.account?.username ??
                              post?.reblog?.account?.id
                            }`
                      }
                      underline="hover"
                    >
                      {post?.reblog?.group
                        ? post?.reblog?.group?.title
                        : post?.reblog?.page
                        ? post?.reblog?.page?.title
                        : post?.reblog?.account?.display_name
                        ? post?.reblog?.account?.display_name
                        : post?.reblog?.account?.username}
                    </Link>
                  )}
                </span>
              </span>
              <Divider sx={{ marginTop: '8px' }} />
            </div>
          ) : meInfo.id !== post.account.id && post?.post_type === 'target' ? (
            <div
              style={{
                width: 'calc(100% - 32px)',
                margin: '16px 16px 0px 16px'
              }}
            >
              <Typography sx={{ color: 'text.secondary', fontSize: 15 }}>
                <Link
                  sx={{
                    color: 'text.primary',
                    fontWeight: 600
                  }}
                  href={
                    post?.page
                      ? `/page/${post?.page?.username ?? post?.page?.id}`
                      : `/user/${post?.account?.username ?? post?.account?.id}`
                  }
                  underline="hover"
                >
                  {post?.page
                    ? post?.page?.title
                    : post?.account?.display_name
                    ? post.account.display_name
                    : post?.account?.username}
                </Link>{' '}
                {post?.status_target?.target_status === 'completed'
                  ? 'đã hoàn thành một mục tiêu'
                  : 'đã công bố mục tiêu mới'}
                {post?.group ? (
                  <span>
                    trong nhóm{' '}
                    <Link
                      sx={{
                        color: 'text.primary',
                        fontWeight: 600
                      }}
                      href={`/group/${
                        post?.group?.username ?? post?.group?.id
                      }`}
                      underline="hover"
                    >
                      {post?.group?.title}
                    </Link>{' '}
                  </span>
                ) : null}
              </Typography>
              <Divider sx={{ marginTop: '8px' }} />
            </div>
          ) : null
        ) : null
      ) : null}

      <ListItem
        style={
          type === 'watch-selected'
            ? { padding: '12px 0px 0' }
            : typePost === 'pinned'
            ? { padding: '0px 12px' }
            : { padding: '12px 16px 0' }
        }
        secondaryAction={
          (type && type === 'sharePost') || embed === 'embed' ? null : type !==
              'rating' && typeShare !== 'share-messenger' ? (
            <IconButton aria-label="settings" onClick={handleClick}>
              <i className="far fa-ellipsis-h" />
            </IconButton>
          ) : type === 'rating' && meInfo?.id === post?.account?.id ? (
            <IconButton aria-label="settings" onClick={handleClick}>
              <i className="far fa-ellipsis-h" />
            </IconButton>
          ) : null
        }
        disablePadding
      >
        <ListItemAvatar>{renderAvatar()}</ListItemAvatar>

        <ListItemText
          id={post?.id}
          primary={renderTextPrimary()}
          secondary={renderTextSecondary()}
        />
      </ListItem>

      <MenuAction
        roleGroupMember={roleGroupMember}
        post={post}
        setPostData={setPostData}
        setMediaData={setMediaData}
        anchorEl={anchorEl}
        openPopper={openPopper}
        setOpenPopper={setOpenPopper}
        handleCloseMenu={handleCloseMenu}
        listReport={ReportInfo}
        type={type}
        typeAction={typeAction}
        setOpenDialogRating={setOpenDialogRating}
        setUpdateRating={setUpdateRating}
        setCreateRating={setCreateRating}
        itemRating={itemRating}
        ratingSelected={ratingSelected}
        setRatingSelected={setRatingSelected}
        idPost={idPost}
        setValue={setValue}
        setFiles={setFiles}
        valueRating={valueRating}
        setPostDetail={setPostDetail}
        setListPostRating={setListPostRating}
        setOpenSnackbar={setOpenSnackbar}
        setNoti={setNoti}
        pageInfo={pageInfo}
        eventInfo={eventInfo}
        courseInfo={courseInfo}
        projectInfo={projectInfo}
        isWaitingPost={isWaitingPost}
      />
      {openTagFriend && (
        <DialogViewTagFriend
          idPost={post?.id}
          open={openTagFriend}
          setOpen={setOpenTagFriend}
        />
      )}
    </>
  );
});

export default Index;
